import React from "react";
import { GET_BILL_QUERY } from "../query";
import GraphqlClient from "@root/services/GraphqlService";
import getBill from "@root/mock-data/getBill";

export const GET_BILL_VARIABLE = (params) => ({
  variables: {
    input: {
      ...params,
    },
  },
});

export default function getBills(params) {
  const { tableId } = params;
  const dataResponse = getBill[tableId] || getBill[1];
  return new Promise((resolve, reject) => resolve(dataResponse));

  // return GraphqlClient.get(GET_BILL_QUERY, GET_BILL_VARIABLE(params))
  //   .then(({ data, errors }) => {
  //     const { tableId } = params;
  //     const dataResponse = getBill[tableId] || getBill[1];
  //     return dataResponse;
  //   })
  //   .catch((error) => {
  //     throw error;
  //   });
}
