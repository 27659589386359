import React from "react";
import PropTypes from "prop-types";
import { Paper, Grid, Divider, Icon, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { formatToCurrency } from "@utils/currency.js";
import Icons from "@components/icons";
import Typography from "@components/typography";
import Card from "@components/card";
import langFn from "@util/lang";

const checkerDivStyle = {
  position: "fixed",
  top: "183px",
  background: "aqua",
  width: "50px",
  zIndex: 99,
  left: "48px",
  height: 27,
};

const borderBottomStyle = {
  borderBottom: "1px solid #B8B8B8",
};

const textStyle = {
  fontSize: 12,
  fontWeight: 500,
};

const headerTextStyle = {
  fontWeight: 600,
  fontSize: 14,
};

const itemTextStyle = {
  fontWeight: "normal",
  fontSize: 14,
};

const subItemTextStyle = {
  fontWeight: "normal",
  fontSize: 12,
};

const calAmount = (item) => {
  const { discount, unitPrice, quantity } = item;
  const totalAmount = quantity * unitPrice;
  return totalAmount - discount;
};
function BillSummary({ classes, items, bill: orderDetail }) {
  const {
    itemTotalAmount,
    tableName: tableNumber,
    subTotal,
    serviceCharge,
    totalAmount,
    outlet,
  } = orderDetail;

  const { currencySymbol } = outlet;

  const defaultLogo =
    "https://fmb-assets-dev.s3.eu-west-1.amazonaws.com/fmb-dev-multimedia/default-logo.png";
  const logo = orderDetail?.outlet?.logoUrl || defaultLogo;
  return (
    <div>
      <Box padding={1}>
        <Typography variant="h3">
          <strong>{langFn("Demo")}</strong>
        </Typography>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
        className={classes.paper}
      >
        {/* <div style={checkerDivStyle}>width measurement bar </div> */}
        <Grid xs={12} container justifyContent="center" alignContent="center">
          <div className={classes.imageContainer}>
            <img src={logo} height="63" />
          </div>
          <Typography className={classes.outletName}>
            {orderDetail?.outlet?.name}
          </Typography>
        </Grid>
        <Grid xs={6} item style={{ paddingBottom: 0 }}>
          <Typography
            variant="h3"
            align="left"
            weight="semiBold"
            className={classes.tableName}
          >
            Table {tableNumber}
          </Typography>
        </Grid>
        <Grid xs={6} item style={{ paddingBottom: 0 }} />

        <Grid container item spacing={1}>
          {items.map(({ comboItems = [], ...item }, index) => (
            <React.Fragment key={`${item.name}-${index}`}>
              <Grid item xs={1}>
                <Typography variant="h4" align="left" style={itemTextStyle}>
                  <strong>{item.quantity}</strong>
                </Typography>
              </Grid>
              <Grid xs={7} item>
                <Typography variant="h4" align="left" style={itemTextStyle}>
                  {item.name}
                </Typography>
                {comboItems.map((childItem) => (
                  <Typography
                    variant="h5"
                    align="left"
                    style={subItemTextStyle}
                    key={JSON.stringify(childItem)}
                  >
                    + {childItem.name} ({currencySymbol}
                    {formatToCurrency(calAmount(childItem))})
                  </Typography>
                ))}
              </Grid>
              <Grid xs={4} item>
                <Typography variant="h4" align="right" style={itemTextStyle}>
                  {currencySymbol} {formatToCurrency(item.costPrice)}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        <Grid container item xs={12}>
          <Grid xs={8} item>
            <Typography variant="h5" align="left" gutterBottom>
              <strong style={headerTextStyle}>{langFn("Subtotal")}</strong>
            </Typography>
            {serviceCharge > 0 && (
              <Typography variant="h5" align="left" style={itemTextStyle}>
                {/* {parseFloat((serviceCharge * 100) / subTotal).toFixed(2)}%  */}
                {langFn("Service Charge")}
              </Typography>
            )}
          </Grid>
          <Grid xs={4} item>
            <Typography variant="h5" align="right" gutterBottom>
              <strong style={headerTextStyle}>
                {currencySymbol} {formatToCurrency(subTotal)}
              </strong>
            </Typography>
            {serviceCharge > 0 && (
              <Typography variant="h5" align="right" style={itemTextStyle}>
                {currencySymbol} {formatToCurrency(serviceCharge)}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid xs={6} item>
            <Typography variant="h5" align="left">
              <strong style={headerTextStyle}>{langFn("Total")}</strong>
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography variant="h5" align="right">
              <strong style={headerTextStyle}>
                {currencySymbol} {formatToCurrency(totalAmount)}
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  paper: {
    border: "1px solid #E8E8E8",
    backgroundColor: "#fff",
    borderRadius: 23,
    width: "100%",
    margin: "0 auto",
    padding: "17px 12px 10px 12px",
    boxShadow: "0px 0px 4px 3px rgb(0 0 0 / 4%)",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  outletName: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "1.25rem",
  },
  tableName: {
    textDecoration: "underline",
    textUnderlineOffset: "2px",
    textDecorationStyle: "solid",
    textDecorationThickness: "2px",
  },
});

export default withStyles(styles)(BillSummary);
