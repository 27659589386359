import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles, Divider } from "@material-ui/core";
import TextField from "@components/text-field";
import ZigZagText from "@components/zig-zag-text";
import { formatToCurrency } from "@utils/currency";
import Typography from "@components/typography";
import langFn from "@util/lang";
function SplitBillByEqualShare({ classes, updateIamPaying, ...props }) {
  const [numberOfPeople, setNumberOfPeople] = React.useState(0);
  const [payingFor, setPayingFor] = React.useState(0);
  const [name, setName] = React.useState("");
  const [amount, setAmount] = React.useState(0);
  const [error1, setError1] = React.useState(false);
  const [error2, setError2] = React.useState(false);

  // {
  //   message: "Cannot split more that total amount",
  //   status: false,
  // },
  // {
  //   message: "Please enter name.",
  //   status: false,
  // },

  const itemTotalAmount = 24;
  const divide = () =>
    Boolean(numberOfPeople)
      ? itemTotalAmount / numberOfPeople
      : itemTotalAmount;

  const multiply = () => {
    const costForIndividual = divide();
    const amount = Boolean(payingFor) ? costForIndividual * payingFor : 0;
    updatePaymentAmount(amount);
    return amount;
  };

  const updatePaymentAmount = (amount) => {
    if (!amount) {
      return false;
    }

    if (amount && !name) {
      if (!error1) {
        setError1(true);
      }
      amount && updateIamPaying(amount);
      amount && props.updateTotalAmount(amount);
      return false;
    } else {
      error1 && setError1(false);
    }

    if (amount > itemTotalAmount) {
      if (!error2) {
        setError2(true);
      }
      amount && updateIamPaying(amount);
      amount && props.updateTotalAmount(amount);
      return false;
    } else {
      error2 && setError2(false);
    }
    amount && updateIamPaying(amount);
    amount && props.updateTotalAmount(amount);
  };

  const remainingAmount = multiply();

  return (
    <Grid container spacing={2} className={classes.wrapper}>
      <Grid item xs={12} className={classes.extraPadding}>
        <Typography variant="h2" className={classes.letterSpacing} gutterBottom>
          <strong>{langFn("Split by equal share")}</strong>
        </Typography>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.letterSpacing}
        >
          {langFn("Split the bill equally between your friends/family")}.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.extraPadding}>
        {error1 && (
          <Typography variant="h5" color="error" align="center" gutterBottom>
            {langFn("Please enter name")}.
          </Typography>
        )}
        <TextField
          placeholder={langFn("Your name")}
          fullWidth
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          size="small"
          className={classes.textCenter}
        />
      </Grid>

      <Grid
        item
        xs={12}
        container
        spacing={1}
        alignItems="center"
        justifyContent="center"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Grid item xs="6">
          <Typography variant="h4" weight="medium" align="right">
            {langFn("I want to split by")}
          </Typography>
        </Grid>

        <Grid item xs="2">
          <Typography align="center">
            <TextField
              value={numberOfPeople}
              onChange={({ target: { value } }) => setNumberOfPeople(value)}
              fullWidth
              type="number"
              size="small"
              inputProps={{ className: classes.textField }}
              className={classes.textCenter}
              fullWidth
              style={{ width: 37 }}
            />
          </Typography>
        </Grid>

        <Grid item xs="4">
          <Typography variant="h4" weight="medium" align="left">
            {langFn("people")}
          </Typography>
        </Grid>
        <Grid item xs="6">
          <Typography variant="h4" weight="medium" align="right">
            & {langFn("pay for")}
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography align="center">
            <TextField
              fullWidth
              size="small"
              value={payingFor}
              type="number"
              onChange={({ target: { value } }) => setPayingFor(value)}
              inputProps={{ className: classes.textField }}
              className={classes.textCenter}
              style={{ width: 37 }}
              fullWidth
            />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {" "}
        </Grid>
      </Grid>
      <Grid item>
        {" "}
        <p> </p>
      </Grid>

      <Grid item container xs={12} justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            weight="medium"
            align="center"
            className={classes.letterSpacing}
          >
            {langFn("I am paying")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            weight="medium"
            align="center"
            className={classes.greenText}
          >
            <strong>
              {props.currencySymbol} {formatToCurrency(multiply())}
            </strong>
          </Typography>
        </Grid>

        {error2 && (
          <Grid item xs={12}>
            <Typography variant="h5" align="center" color="error">
              {langFn("Cannot pay more than total amount")} (
              {props.currencySymbol} {formatToCurrency(itemTotalAmount)})
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

SplitBillByEqualShare.propTypes = {};

const styles = (theme) => ({
  biggerFont: {
    fontSize: 16,
    fontWeight: 600,
  },
  fontWeight: {
    fontWeight: 500,
    letterSpacing: "0.02em",
  },
  extraPadding: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  wrapper: {
    boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
    borderRadius: 20,
    padding: "12px 12px",
    width: "100%",
    margin: "0 auto",
  },
  letterSpacing: {
    letterSpacing: "0.02em",
  },
  greenText: {
    color: theme.palette.success.main,
  },
  payingForText: {
    fontWeight: 600,
  },
  textField: {
    paddingLeft: "0 !important",
    textAlign: "center",
  },

  textCenter: {
    "& input": {
      textAlign: "center",
      paddingLeft: "0px !important",
    },
  },
});

export default withStyles(styles)(SplitBillByEqualShare);
