import React, { Component } from "react";
import PropTypes from "prop-types";
import BillSummary from "@containers/bill-summary";
import StripePayment from "@containers/stripe-payment";
import SplitBill from "@containers/split-bill";
import Tips from "@containers/tips";
import { Grid, Typography } from "@material-ui/core";
import NoBillFound from "@components/no-bill-found";
import LoadingBill from "@components/loading-bill";

const ShowTips = ({ showSplitBill, showCardMode }) => {
  const element = (
    <Grid xs={12} item>
      <Tips />
    </Grid>
  );

  if (showCardMode) {
    return element;
  }

  return null;
};

class Checkout extends Component {
  state = {
    isLoading: true,
  };
  componentDidMount = () => {
    const { getBillDetails, match } = this.props;
    const { tableId } = match.params;
    if (!tableId) {
      return alert("Invalid table id");
    }

    getBillDetails({ tableId }).finally(() =>
      setTimeout(() => this.setState({ isLoading: false }), 2000)
    );
  };

  render() {
    const { bill, match, items, ui, orderDetail = {} } = this.props;
    const { tableId } = match.params;
    const { id, outlet = {} } = bill;
    const { totalAmount } = orderDetail;
    const { version, logo, brandingLogo, showSplitBill, paymentMode } = ui;
    const { banner } = outlet;

    const { isLoading } = this.state;
    if (!bill?.id && !isLoading) {
      return <NoBillFound />;
    }

    // const currentLocation = window.location.href;
    // let banner = "/demo-assets/pound-bottom-banner.svg";
    // if (currentLocation.includes("4")) {
    //   banner = "/demo-assets/dollor-bottom-banner.svg";
    // }
    // if (tableId && tableId.includes("fr")) {
    //   banner = "/demo-assets/banner-french.png";
    // }

    return (
      <>
        {isLoading && <LoadingBill />}
        <Grid container spacing={5} style={{}}>
          <Grid xs={12} item>
            <BillSummary />
          </Grid>
          <Grid xs={12} item>
            <SplitBill />
          </Grid>

          {id && totalAmount > 0 && (
            <Grid xs={12} item>
              <StripePayment />
            </Grid>
          )}
          {/* <Grid xs={12} item container justifyContent="center">
            <img
              src="assets/images/logo2.svg"
              style={{ height: 24 }}
              alt="fetch-my-bill"
            />
          </Grid> */}
          <Grid xs={12} item container justifyContent="center">
            <Grid xs={12} item container justifyContent="center">
              <a
                href="https://fetchmybill.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={banner}
                  style={{ marginBottom: -24, width: "100%" }}
                  alt="fetch-my-bill"
                />
              </a>
            </Grid>
            {/* <Grid xs={12} item>
              <Typography variant="h5" align="center">
                Version {version}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </>
    );
  }
}

Checkout.propTypes = {
  getBillDetails: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  bill: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default Checkout;
