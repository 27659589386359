import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@material-ui/core";
import Typography from "@components/typography";

function FeedbackMessage({ dialogFeedback, classes }) {
  return (
    <Grid xs={12} item container justifyContent="center" spacing={1}>
      {dialogFeedback && (
        <Grid item xs={12}>
          <Typography variant="h2" weight="semiBold" align="center">
            Your feedback has been sent.
          </Typography>
          <Typography variant="h2" weight="semiBold" align="center">
            See you again soon!
          </Typography>
          <Typography align="center">
            <img src="./assets/images/zigzag-dark.png" alt="bottom" />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

FeedbackMessage.propTypes = {};

export default FeedbackMessage;
