import React from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Typography,
  withStyles,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import langFn from "@util/lang";

function LoadingBill({ classes }) {
  return (
    <Dialog className={classes.modal} open>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={4}
        style={{ paddingTop: "61%" }}
        className={classes.root}
      >
        <Grid xs={12} item>
          <Typography align="center" className={classes.hello}>
            {langFn("Hello")}.
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography align="center" className={classes.desc}>
            {langFn("Loading your bill information")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            {" "}
            <CircularProgress color="secondary" />
          </Typography>
        </Grid>

        <Grid xs={12} item>
          <Typography align="center">
            <img
              src="/assets/images/logo2.svg"
              alt="fetch logo"
              style={{ height: "3rem" }}
            />
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const Styles = {
  root: {
    "& .MuiCircularProgress-root": {
      height: "70px !important",
      width: "70px !important",
      color: "#38c1d8",
    },
  },
  modal: {
    zIndex: "1500 !important",
    "& .MuiPaper-root": {
      height: "100vh",
      margin: 0,
      maxHeight: "100vh",
    },
  },
  hello: {
    fontSize: 25,
    fontWeight: "medium",
  },
  desc: {
    color: "#8E8E8E",
    fontSize: 18,
  },
};

LoadingBill.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(LoadingBill);
