import React from "react";
import PropTypes from "prop-types";
import PaymentSuccess from "@containers/payment-success";

function CheckoutSuccess(props) {
  return <PaymentSuccess />;
}

CheckoutSuccess.propTypes = {};

export default CheckoutSuccess;
