export default (theme) => ({
  inputLabel: {
    "& .MuiFormLabel-root": {
      fontSize: "1.1rem !important",
      fontWeight: 500,
      color: "#000",
      letterSpacing: "0.02em",
      top: "-3px",
      left: 1,
    },
  },
  button: {
    "& .MuiButton-label": {
      fontSize: "0.8rem !important",
      fontWeight: "bold",
    },
  },
});
