export const feedbackPayloadDefaults = {
  feedback: "",
  foodRating: 0,
  serviceRating: 0,
  hygieneRating: 0,
};

export const getPayload = (billNumber, values) => {
  const {
    feedback,
    food: foodRating,
    service: serviceRating,
    hygiene: hygieneRating,
  } = values;

  return {
    billNumber,
    ...feedbackPayloadDefaults,
    feedback,
    hygieneRating,
    serviceRating,
    foodRating,
  };
};
