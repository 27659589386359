import React from "react";
import { useSelector } from "react-redux";
import CheckoutContainer from "@containers/checkout";

function Checkout() {
  const { version, logo, brandingLogo, showSplitBill } = useSelector(
    (state) => state.ui
  );
  const {
    bill: { id },
  } = useSelector((state) => state.bill);

  return <CheckoutContainer />;
}

Checkout.propTypes = {};

export default Checkout;
