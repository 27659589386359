import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Slide from "@material-ui/core/Slide";
import { Grid } from "@material-ui/core";
import SliderInput from "@components/slider-input";
// import Slider2 from "@components/sensor-state";
import PrimaryButton from "@components/primary-button";
import LangFn from "@util/lang";
import langFn from "@util/lang";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#fff",
    color: "#C4C4C4",
    boxShadow: "none",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RatingForm({
  handleClose,
  feedbackSetting,
  onSave,
  rating: savedRating,
}) {
  const classes = useStyles();

  const [rating, setRating] = useState({
    food: 0,
    service: 0,
    hygiene: 0,
    recommendation: 0,
  });

  const onChange = (value, name) => {
    const scaledValue = value / 10;
    setRating({ ...rating, [name]: scaledValue });
  };

  useEffect(() => {
    console.log("Reached");
    setRating({
      ...rating,
      ...savedRating,
    });
  }, []);

  const onSaveHandler = () => {
    console.log(rating);
    onSave(rating);
  };

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      TransitionComponent={Transition}
      open
    >
      <div style={{ padding: 10 }}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{ padding: 20, height: "100%" }}>
          <Grid
            container
            alignContent="center"
            spacing={4}
            style={{ height: "100%" }}
          >
            {feedbackSetting?.food && (
              <Grid item xs={12}>
                <SliderInput
                  title={`${LangFn("How was the food")}?`}
                  onChangeCommitted={(e, value) => onChange(value, "food")}
                  name="food"
                  defaultValue={rating?.food}
                />
              </Grid>
            )}
            {feedbackSetting?.service && (
              <Grid item xs={12}>
                <SliderInput
                  title={`${LangFn("How was the service")}?`}
                  onChangeCommitted={(e, value) => onChange(value, "service")}
                  name="service"
                  defaultValue={rating?.service}
                />
              </Grid>
            )}
            {feedbackSetting?.hygiene && (
              <Grid item xs={12}>
                <SliderInput
                  title={`${LangFn("How was the hygiene")}?`}
                  onChangeCommitted={(e, value) => onChange(value, "hygiene")}
                  name="hygiene"
                  defaultValue={rating?.hygiene}
                />
              </Grid>
            )}
            {feedbackSetting?.recommendation && (
              <Grid item xs={12}>
                <SliderInput
                  title={langFn(
                    "How likely would you recommend Ted’s Bistro to the others? (10 Most Likely)"
                  )}
                  onChangeCommitted={(e, value) =>
                    onChange(value, "recommendation")
                  }
                  name="recommendation"
                  defaultValue={rating?.recommendation}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <PrimaryButton onClick={onSaveHandler}>
                {langFn("Save")}
              </PrimaryButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </Dialog>
  );
}
