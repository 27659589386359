import React from "react";
import PropTypes from "prop-types";
// Material UI Imports
import { Grid, Box } from "@material-ui/core";
import Typography from "@components/typography";
import { formatToCurrency } from "@utils/currency";
import { useSelector } from "react-redux";
import langFn from "@util/lang";

const iconStyle = {
  height: 27,
  width: 27,
  background: "#F2F2F2",
  borderRadius: "50%",
  marginRight: 10,
};

function PaymentSuccessOverview() {
  const { totalAmount = 0, tipAmount = 0 } = useSelector(
    (state) => state.order
  );
  const { outlet } = useSelector((state) => state?.bill?.bill);
  const { currencySymbol } = outlet;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" weight="medium" gutterBottom>
          {langFn("Thank you for dining with us")},
          <br />
          {langFn("see you again soon")}!
        </Typography>
        <Typography variant="h3" color="secondary">
          <Box display="flex" alignItems="center">
            <img
              alt="success-tick"
              src="/assets/images/success-logo.svg"
              style={iconStyle}
            />
            {langFn("Payment Completed")} - {currencySymbol}{" "}
            {formatToCurrency(totalAmount + tipAmount)}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}

PaymentSuccessOverview.propTypes = {
  totalAmount: PropTypes.string.isRequired,
  tipAmount: PropTypes.string.isRequired,
};

export default PaymentSuccessOverview;
