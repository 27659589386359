import { createSlice } from "@reduxjs/toolkit";

const items = createSlice({
  name: "payment",
  initialState: {
    message: "",
    isLoading: false,
  },
  reducers: {
    addMessage: (state, { payload }) => {
      return {
        ...state,
        message: payload,
      };
    },
    setIsLoading: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
});

export const action = items.actions;

export default items;
