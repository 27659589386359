import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Library Imports
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
// Components Imports
import SendEmailReceipt from "@root/graphql/bill/hooks/sendReceiptEmail";
import DownloadEmailReceipt from "@root/graphql/bill/hooks/downloadReceiptEmail";
import SendPaymentReceiptForm from "@components/send-payment-receipt";
import Modal from "@components/modal";
import { useParams } from "react-router-dom";

const downloadFile = (file, name) => saveAs(file, name);

function SendPaymentReceipt(props) {
  const [email, setEmail] = useState("");
  const [dialogReceipt, setDialogReceipt] = useState(false);
  const [dialogReceiptError, setDialogReceiptError] = useState(false);
  const { billNumber } = useParams();

  const onChange = ({ target: { value } }) => setEmail(value);

  const sendReceiptEmail = () => {
    if (!String(email).includes("@")) {
      return setDialogReceiptError(true);
    } else {
      setDialogReceiptError(false);
    }

    // SendEmailReceipt({ email, billNumber }).then((res) =>
    //   setDialogReceipt(true)
    // );
    setDialogReceipt(true);
  };

  const onDownloadClick = () => {
    // DownloadEmailReceipt({
    //   billNumber,
    // }).then((res) => {
    //   if (res?.cardReceiptFileUrl) {
    //     downloadFile(res?.cardReceiptFileUrl, "payment-receipt.pdf");
    //   }

    //   if (res?.billReceiptFileUrl) {
    //     setTimeout(() => {
    //       downloadFile(res?.billReceiptFileUrl, "bill-receipt.pdf");
    //     }, 1000);
    //   }
    // });
    downloadFile(
      "/demo-assets/payment-receipt-dollor.pdf",
      "payment-receipt.pdf"
    );
    downloadFile("/demo-assets/bill-receipt-dollor.pdf", "bill-receipt.pdf");
  };

  // useEffect(() => {
  //   if (!String(email).includes("@") && !dialogReceiptError) {
  //     return setDialogReceiptError(true);
  //   } else {
  //     return setDialogReceiptError(false);
  //   }
  // }, [email]);

  return (
    <>
      <SendPaymentReceiptForm
        {...props}
        classes={{}}
        value={email}
        name={"email"}
        onChange={onChange}
        sendReceiptEmail={sendReceiptEmail}
        onDownloadClick={onDownloadClick}
        dialogReceiptError={dialogReceiptError}
      />
      {dialogReceipt && (
        <Modal
          message="Your receipt has been sent!"
          onClose={() => setDialogReceipt(false)}
        />
      )}
    </>
  );
}

SendPaymentReceipt.propTypes = {};

export default SendPaymentReceipt;
