import { createSelector } from "@reduxjs/toolkit";
import groupBy from "lodash.groupby";

const itemSelector = (state) => state.item;

export const getItem = createSelector(itemSelector, (state) => state.items);

export const getGroupedItem = createSelector(itemSelector, (state) =>
  groupBy(state.items, "category")
);

export const getErrors = createSelector(itemSelector, (state) => state.error);

export default itemSelector;
