import langMap from "../language";

const langFn = (string) => {
  const currentLocation = window.location.href;
  if (!currentLocation.includes("fr")) {
    return string;
  }

  const stripSpecialChar = String(string).replace(/[^\w\s]/gi, "");
  const addUnderscore = stripSpecialChar.replaceAll(" ", "_");
  const toLowerCase = addUnderscore.toLowerCase();

  console.log(langMap[toLowerCase] ? string : toLowerCase);
  return langMap[toLowerCase] ?? string;
};

export default langFn;
