import { DOWNLOAD_RECEIPT_EMAIL } from "../query";
import GraphqlClient from "@root/services/GraphqlService";

export const DOWNLOAD_RECEIPT_VARIABLE = (params) => ({
  variables: {
    ...params,
  },
});

export default function downloadReceiptEmail(params) {
  return GraphqlClient.get(
    DOWNLOAD_RECEIPT_EMAIL,
    DOWNLOAD_RECEIPT_VARIABLE(params)
  )
    .then(({ data, errors }) => {
      if (errors) {
        throw errors[0];
      }
      const dataResponse = data?.getBillFile ?? false;
      return dataResponse;
    })
    .catch((error) => {
      throw error;
    });
}
