import { createSlice } from "@reduxjs/toolkit";

const data = [
  {
    name: "Spicy Mixed Olives",
    quantity: 1,
    price: 3.7,
    category: "starter",
  },
  {
    name: "Halloumi Sticks",
    quantity: 1,
    price: 3.8,
    category: "starter",
  },
  {
    name: "Sunset Burger",
    quantity: 1,
    price: 8.25,
    category: "main",
  },
  {
    name: "Chicken Butterfly",
    quantity: 1,
    price: 8.25,
    category: "main",
  },
];

const items = createSlice({
  name: "items",
  initialState: {
    items: data,
    isLoading: null,
    error: null,
  },
  reducers: {
    updateItem: (state, { payload }) => {
      // console.log(payload);
      return {
        ...state,
        items: payload,
      };
    },
    status: (state, { payload }) => ({ ...state, isLoading: payload }),
    error: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const action = items.actions;

export default items;
