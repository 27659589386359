import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

function Card({ children }) {
  return (
    <Box component="div" boxShadow="3" borderRadius={7} p={3}>
      {children}
    </Box>
  );
}

// boxShadow="0px 0px 4px 2px rgba(0, 0, 0, 0.05);"

Card.propTypes = {};

export default Card;
