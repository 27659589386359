import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

export function CardNumberElement(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        " ",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        " ",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        " ",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      keepCharPositions
    />
  );
}

CardNumberElement.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export function CardExpiryElement(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-1]/, /[0-9]/, " ", "/", " ", /\d/, /\d/]}
      placeholderChar={"\u005F"}
      keepCharPositions
    />
  );
}

CardExpiryElement.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export function CardCvcElement(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/]}
      placeholderChar={"\u005F"}
      keepCharPositions
    />
  );
}

CardCvcElement.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
