import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box, withStyles } from "@material-ui/core";
import ZigZagText from "@components/zig-zag-text";
import langFn from "@util/lang";

const ratingCategories = [
  {
    name: "food",
    defaultRating: 0,
  },
  {
    name: "service",
    defaultRating: 0,
  },
  {
    name: "hygiene",
    defaultRating: 0,
  },
  {
    name: "recommendation",
    defaultRating: 0,
  },
];

function FeedbackRatingOverview({
  classes,
  openRatingForm,
  feedbackSetting,
  rating,
  successMessage,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" style={{ fontSize: 18 }}>
          {langFn("How Did We Get On")}?
        </Typography>
        <Typography variant="h4" color="secondary">
          {langFn("Tell us about your experience")},{" "}
          {langFn("we’d love to know")}!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {ratingCategories.map((category) => {
          if (!feedbackSetting[category?.name]) {
            return null;
          }
          return (
            <Box
              borderRadius={32}
              bgcolor="#F5F5F5"
              p={1.5}
              pl={1.8}
              pr={1.8}
              mb={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              onClick={openRatingForm}
            >
              <Box className={classes.categoryTitle}>
                {langFn(String(category.name))}
              </Box>
              <Box className={classes.rating}>{rating[category.name]} / 10</Box>
            </Box>
          );
        })}
      </Grid>
      {successMessage && (
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <ZigZagText text="Thank You" variant="dark" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

FeedbackRatingOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  openRatingForm: PropTypes.func.isRequired,
  feedbackSettings: PropTypes.object.isRequired,
  rating: PropTypes.object.isRequired,
  successMessage: PropTypes.bool.isRequired,
};

const Style = () => ({
  categoryTitle: {
    textTransform: "capitalize",
    fontSize: 18,
  },
  rating: {
    color: "#787878",
    fontSize: 18,
    fontWeight: 500,
  },
});

export default withStyles(Style)(FeedbackRatingOverview);
