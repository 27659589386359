import { createSelector } from "@reduxjs/toolkit";

const billSelector = (state) => state.bill;

export const getBill = createSelector(billSelector, (state) => state.bill);
export const getItems = createSelector(billSelector, (state) => state.items);
export const getErrors = createSelector(billSelector, (state) => state.error);
export const isLoading = createSelector(
  billSelector,
  (state) => state.isLoading
);

export default billSelector;
