import { createSlice } from "@reduxjs/toolkit";

const items = createSlice({
  name: "splitBill",
  initialState: {
    info: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateInfo: (state, { payload }) => {
      return {
        ...state,
        info: payload,
      };
    },
    isLoading: (state, { payload }) => {
      return { ...state, isLoading: payload };
    },
    error: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const action = items.actions;

export default items;
