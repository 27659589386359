import { createSelector } from "@reduxjs/toolkit";
import groupBy from "lodash.groupby";

const tipsSelector = (state) => state.tips;

export const getTips = createSelector(tipsSelector, (state) => {
  // console.log(state);
  return state?.appSettings;
});

export const getGroupedTips = createSelector(tipsSelector, (state) =>
  groupBy(state.appSettings, "category")
);

export const getErrors = createSelector(tipsSelector, (state) => state.error);

export default tipsSelector;
