import React, { Component } from "react";
import PropTypes from "prop-types";

import SplitBillComponent from "@components/split-bill";

class SplitBill extends Component {
  state = {};
  componentDidMount = () => {
    const { getOrderDetails, match } = this.props;
    const { tableId, orderId } = match.params;
    getOrderDetails({ tableId, orderId });

    // console.log("Mounted");
  };

  render() {
    const { items, match, orderDetail, toggleCheckoutForm, totalAmount } =
      this.props;
    const { tableId } = match.params;
    return (
      <SplitBillComponent
        amount={totalAmount}
        updateAmount={() => {}}
        {...this.props}
      />
    );
  }
}

SplitBill.propTypes = {
  getOrderDetails: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
};

export default SplitBill;
