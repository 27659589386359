import React from "react";
import PropTypes from "prop-types";

function EmptyTable(props) {
  return (
    <div>
      <h1>Empty table</h1>
    </div>
  );
}

EmptyTable.propTypes = {};

export default EmptyTable;
