import React from "react";
import PropTypes from "prop-types";
import { Typography as MuiTypography, Box } from "@material-ui/core";

const FontWeightType = {
  semiBold: 600,
  medium: 500,
};

const LetterSpacing = {
  semiBold: "0.02em",
  medium: "0.02em",
};

function Typography({ weight, subText, children, ...props }) {
  return (
    <MuiTypography {...props}>
      <Box
        fontWeight={FontWeightType[weight]}
        letterSpacing={LetterSpacing[weight]}
      >
        {children}
      </Box>
    </MuiTypography>
  );
}

Typography.propTypes = {
  weight: PropTypes.string,
  subText: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Typography.defaultProps = {
  weight: 400,
  subText: false,
};

export default Typography;
