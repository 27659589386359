import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button as MuiButton,
  Typography,
  withStyles,
  InputAdornment,
  ClickAwayListener,
} from "@material-ui/core";
import TextField from "@components/text-field";

import {
  Button,
  buttonProps,
  buttonPropsContained,
  buttonPropsOutlined,
} from "./constant";
import lang from "@utils/lang";

function Tips(props) {
  const {
    classes,
    updateTipAmount,
    tipsButtonEnabled,
    customTipAmount,
    totalAmount,
    updateCustomTipAmount: setCustomTipAmount,
    updateTipsButtonEnabled: setTipsButtonEnabled,
    appSettings,
  } = props;

  const [fieldActive, setFieldActive] = React.useState(
    Boolean(customTipAmount) || false
  );

  const {
    label: tipsLabel = "Tips",
    tipsDefaultValues = [1, 2, 3],
    tipsEnabled,
    tipsType,
  } = appSettings;

  if (!tipsEnabled) {
    return null;
  }

  const isPercentageEnabled = tipsType === "ByPercentage";

  const updateTipsAmountByPercentage = (percentage, buttonIndex) => {
    const tipAmount = (totalAmount * Math.abs(percentage)) / 100;
    return tipAmount;
  };

  const updateTipEnabled = (percentage, buttonIndex) => {
    let tipAmount = percentage;
    if (isPercentageEnabled) {
      tipAmount = updateTipsAmountByPercentage(percentage, buttonIndex);
    }
    if (buttonIndex === tipsButtonEnabled) {
      setTipsButtonEnabled(-1);
      updateTipAmount(0);
      return true;
    }
    updateTipAmount(tipAmount);
    setTipsButtonEnabled(buttonIndex);
    setCustomTipAmount("");
  };

  const updateCustomTips = (rawTipAmount) => {
    let tipAmount = Math.abs(rawTipAmount);
    // tipAmount = String(tipAmount).replaceAll("-", "");
    // tipAmount = String(tipAmount).replaceAll("+", "");
    // tipAmount = parseFloat(tipAmount);

    if (!isNaN(tipAmount)) {
      updateTipAmount(tipAmount);
      setTipsButtonEnabled(-1);
      setCustomTipAmount(tipAmount);
      return true;
    }

    setCustomTipAmount("");
    updateTipAmount(0);
  };

  const generateTipsDisplayValue = (val) => {
    return `${!isPercentageEnabled ? "£" : ""}${val} ${
      isPercentageEnabled ? "%" : ""
    }`;
  };
  // React.useEffect(() => {
  //   if (amount && tipEnabled !== -1) {
  //     updateAmount(accAmount + tipEnabled);
  //   }
  // }, [amount]);
  return (
    <Grid container justifyContent="space-between" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h3" align="left">
          {tipsLabel}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          {...buttonProps(tipsButtonEnabled, 1)}
          onClick={() => updateTipEnabled(tipsDefaultValues[0], 1)}
        >
          {generateTipsDisplayValue(tipsDefaultValues[0])}
        </Button>
      </Grid>
      <Grid item>
        <Button
          {...buttonProps(tipsButtonEnabled, 2)}
          onClick={() => updateTipEnabled(tipsDefaultValues[1], 2)}
        >
          {generateTipsDisplayValue(tipsDefaultValues[1])}
        </Button>
      </Grid>
      <Grid item>
        <Button
          {...buttonProps(tipsButtonEnabled, 3)}
          onClick={() => updateTipEnabled(tipsDefaultValues[2], 3)}
        >
          {generateTipsDisplayValue(tipsDefaultValues[2])}
        </Button>
      </Grid>
      <Grid item xs={4}>
        <ClickAwayListener
          onClickAway={() => setFieldActive(Boolean(customTipAmount) || false)}
        >
          <TextField
            placeholder={!fieldActive ? lang("Custom Tip") : ""}
            size="small"
            value={customTipAmount}
            onClick={() => setFieldActive(true)}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: fieldActive ? (
                <InputAdornment position="start">
                  <Typography variant="h4" align="center">
                    {/* {isPercentageEnabled ? "%" : "£"} */}£
                  </Typography>
                </InputAdornment>
              ) : (
                " "
              ),
            }}
            onChange={({ target: { value } }) =>
              updateCustomTips(parseFloat(value))
            }
            className={`${classes.textField} lessPadding ${
              fieldActive ? classes.active : null
            }`}
            type="number"
            fullWidth
          />
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
}

// const styles = () => ({
//   button: {
//     fontWeight: "bold",
//     borderColor: "#000",
//     "& .MuiButton-label": {
//       color: "#000",
//     },

//   },
// });

Tips.propTypes = {};

const Styles = (theme) => ({
  textCenter: {
    "& input": {
      textAlign: "center",
      paddingLeft: "0px !important",
    },
  },
  active: {
    "& input": {
      paddingLeft: "0px !important",
      textAlign: "left",
    },
    "& .MuiInputAdornment-root": {
      paddingLeft: 7,
    },
  },
  textField: {
    borderRadius: "5px !important",
    "& input": {
      height: "30px !important",
      paddingLeft: "0px !important ",
    },
  },
});

export default withStyles(Styles)(Tips);
