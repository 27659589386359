import { createSelector } from "@reduxjs/toolkit";

const paymentSelector = (state) => state.order;

export const getTotalAmount = createSelector(
  paymentSelector,
  (state) => state.totalAmount
);

export const getItemTotalAmount = createSelector(
  paymentSelector,
  (state) => state.itemTotalAmount
);

export const getTipAmount = createSelector(
  paymentSelector,
  (state) => state.tipAmount
);

export const getErrors = createSelector(
  paymentSelector,
  (state) => state.error
);

export default paymentSelector;
