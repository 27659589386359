import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware, routerReducer } from "react-router-redux";

import { history } from "../App";

import order from "./order/reducer.js";
import ui from "./ui/reducer";
import item from "./items/reducer";
import bill from "./bill/reducer";
import tips from "./tips/reducer";
import payment from "./payment/reducer";
import splitBill from "./splitBill/reducer";
import feedback from "./feedback/reducer";

export const store = configureStore({
  reducer: {
    order: order.reducer,
    ui: ui.reducer,
    item: item.reducer,
    bill: bill.reducer,
    routing: routerReducer,
    tips: tips.reducer,
    payment: payment.reducer,
    splitBill: splitBill.reducer,
    feedback: feedback.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(routerMiddleware(history));
  },
});
