import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import updateFeedback from "../../graphql/feedback/hooks/updateFeedback";

import { useSelector, useDispatch } from "react-redux";

import FeedbackRatingOverview from "@components/feedback-rating-overview";
import RatingForm from "@components/rating-form";
import { useParams, useHistory } from "react-router-dom";
import billAction from "../../store/bill/api";
import { action } from "@root/store/ui/reducer";
import { action as feedbackActions } from "@root/store/feedback/reducer";

const getEnabledRating = (payload, feedbackEnabled) => (string) => {
  try {
    return feedbackEnabled[string] ? payload[string] : null;
  } catch (e) {
    return null;
  }
};
function Rating(props) {
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [rating, setRating] = useState({
    food: 0,
    service: 0,
    hygiene: 0,
    recommendation: 0,
  });
  const history = useHistory();
  //dd

  const bill = useSelector((state) => state.bill?.bill);
  const { tableId } = useParams();

  useEffect(() => {
    if (!bill) {
      dispatch(billAction.getBillDetails({ tableId }).finally(() => {}));
    }
  }, []);

  if (!bill || !bill?.billNumber) {
    return history.push(`/${tableId}`);
  }

  const { billNumber } = bill;
  const { outlet } = bill;
  const { feedbackSetting } = outlet;

  const onSave = (payload) => {
    const isFeedbackEnabled = getEnabledRating(payload, feedbackSetting);
    const ratings = {
      foodRating: isFeedbackEnabled("food"),
      serviceRating: isFeedbackEnabled("service"),
      hygieneRating: isFeedbackEnabled("hygiene"),
      recommendRating: isFeedbackEnabled("recommendation"),
    };

    const rated = Object.values(ratings).filter((rating) => Boolean(rating));
    const averageRating =
      rated.reduce((acc, val) => acc + val, 0) / rated.length;

    // updateFeedback({
    //   ...ratings,
    //   billNumber,
    // }).then((res) => {
    //   setRating({ ...payload });
    //   setOpenModal(false);
    //   setIsSubmitted(true);
    //   dispatch(action.updateFeedbackSubmitted(true));
    // });
    setRating({ ...payload });
    setOpenModal(false);
    setIsSubmitted(true);
    // dispatch(action.updateFeedbackSubmitted(true));
    dispatch(feedbackActions.updateFeedbackSubmitted(true));
    dispatch(feedbackActions.updateAverageRating(averageRating));
    setSuccessMessage(true);
  };

  const handleModalDisplay = () => {
    if (isSubmitted) {
      return;
    }
    setOpenModal(true);
  };

  return (
    <React.Fragment>
      <FeedbackRatingOverview
        openRatingForm={handleModalDisplay}
        feedbackSetting={feedbackSetting}
        rating={rating}
      />
      {openModal && (
        <RatingForm
          handleClose={() => setOpenModal(false)}
          feedbackSetting={feedbackSetting}
          onSave={onSave}
          rating={rating}
        />
      )}
    </React.Fragment>
  );
}

Rating.propTypes = {};

export default Rating;
