import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import Rating from "@components/rating-wrapper";

function PaymentRating({ onChange }) {
  return (
    <Grid xs={12} item container spacing={1} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h2" align="left" style={{ fontWeight: 600 }}>
          How did we get on?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Rating onChange={onChange} />
      </Grid>
      <Grid item xs={12}>
        {" "}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" align="center">
          Thank you!
        </Typography>
      </Grid>
    </Grid>
  );
}

PaymentRating.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PaymentRating;
