import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Checkout from "../pagepans/checkout";
import CheckoutSuccess from "../pagepans/checkout-success";
import PaymentSuccess from "../pagepans/payment-success";
import EmptyTable from "../pagepans/empty-table";
import RedirectComp from "@containers/redirect";

import LogoLayout from "@root/layout/LogoLayout";

import RouteWrapper from "./RouteWrapper";
import { useParams } from "react-router-dom";

function Layout(props) {
  return (
    <Switch>
      <RouteWrapper
        path="/checkout-success"
        component={CheckoutSuccess}
        exact
      />
      <RouteWrapper
        path="/payment-success/:tableId/:outletId/:billNumber"
        component={PaymentSuccess}
        layout={LogoLayout}
        exact
      />
      <RouteWrapper path="/:tableId/:currency?" component={Checkout} exact />
      {/* <RouteWrapper path="/checkout" component={Checkout} exact /> */}

      <RouteWrapper path="/empty-table" component={EmptyTable} exact />

      {/* <Route path="/" component={RedirectComp} /> */}

      <Redirect to={`/1`} />
    </Switch>
  );
}

Route.propTypes = {};

export default Layout;
