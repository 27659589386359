import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";
import { ReactComponent as rating1 } from "./svg/rating/1.svg";
import { ReactComponent as ratingGroup } from "./svg/rating/rating-group.svg";
import { ReactComponent as checked } from "./svg/checked.svg";
import { ReactComponent as zigzag } from "./svg/zigzag.svg";
import { ReactComponent as zigzagLight } from "./svg/zigzag-light.svg";
import { ReactComponent as zigzagDisabled } from "./svg/zigzag-disabled.svg";

const DEFAULT_ICON = "group";
const ICON_MAP = {
  // ENTITIES
  rating1,
  ratingGroup,
  checked,
  zigzag,
  zigzagLight,
  zigzagDisabled,
};

const Icon = ({ variant, ...props }) => {
  //   const color = nativeColor ? nativeColor : DEFAULT_COLOR
  const Icon = ICON_MAP[variant] ? ICON_MAP[variant] : ICON_MAP[DEFAULT_ICON];
  return (
    <SvgIcon
      {...props}
      component={Icon}
      style={{ fill: "transparent", ...props.style }}
    />
  );
};

Icon.propTypes = {
  variant: PropTypes.string,
  nativeColor: PropTypes.string,
};

export default Icon;
