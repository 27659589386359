import { createTheme } from "@material-ui/core/styles";

const fontFamilySecondary = ['"Montserrat"', '"Lato"', "sans-serif"].join(",");
const fontFamily = ["'Poppins'", "sans-serif"].join(",");

export default createTheme({
  fontFamily,
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#8E8E8E",
      text: "#8E8E8E",
    },
    success: {
      main: "#34A853",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "unset",
      },
    },
  },
  typography: {
    fontSize: 12,
    fontFamily,
    h1: {
      fontFamily,
      fontSize: "1.25rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily,
      fontSize: "1rem",
      fontWeight: 500,
    },
    h3: {
      fontFamily,
      fontSize: "0.875rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h4: {
      fontFamily,
      fontSize: "0.75rem",
    },
    h5: {
      fontFamily,
      fontSize: "0.625rem",
    },
    h6: {
      fontFamily,
      fontSize: "0.625rem",
    },
  },
});
