import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Checkout from "./Checkout";
import billAction from "../../store/bill/api";
import * as billSelector from "../../store/bill/selector";
import * as uiSelector from "../../store/ui/selector";
import * as orderSelector from "../../store/order/selector";
//import * as orderSelector from "../../store/order/selector";
// import * as billSelector from "../../store/items/selector";

const mapStateToProps = (state) => ({
  orderDetail: orderSelector.default(state),
  bill: billSelector.getBill(state),
  items: billSelector.getItems(state),
  error: billSelector.getErrors(state),
  isLoading: billSelector.isLoading(state),
  ui: uiSelector.UISelector(state),
});

const mapDispatchToProps = {
  getBillDetails: billAction.getBillDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Checkout));
