import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, withStyles } from "@material-ui/core";
import TextField from "@components/text-field";
import langFn from "@util/lang";

function SendPaymentReceipt({
  onChange,
  classes,
  dialogReceiptError,
  sendReceiptEmail,
  onDownloadClick,
}) {
  return (
    <>
      <Grid xs={12} item container alignItems="flex-end">
        <Grid item xs={8}>
          <Typography
            variant="h2"
            weight="medium"
            style={{ fontSize: 18 }}
            gutterBottom
          >
            {langFn("Enter Email For Receipt")}
          </Typography>
          <TextField
            placeholder={langFn("Email address")}
            type="email"
            size="small"
            name="email"
            onChange={(event) => onChange(event)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={sendReceiptEmail}
            size="small"
            className={classes.button}
          >
            {langFn("Send")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          {dialogReceiptError && (
            <Typography variant="h5" color="error" align="left" gutterBottom>
              {langFn("Please enter valid email address")}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            onClick={onDownloadClick}
            variant="h4"
            color="textSecondary"
            style={{
              color: "#A6A6A6",
              textDecoration: "underline",
              textUnderlineOffset: 2,
              paddingTop: 8,
            }}
          >
            {langFn("Or Download Here")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

SendPaymentReceipt.propTypes = {
  onChange: PropTypes.func.isRequired,
  sendReceiptEmail: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogReceiptError: PropTypes.bool.isRequired,
  dialogReceipt: PropTypes.bool.isRequired,
};

const Styles = () => ({
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: "10px 0 0 10px",
    },
  },
  button: {
    borderRadius: "0 10px 10px 0",
    height: 30,
  },
});

export default withStyles(Styles)(SendPaymentReceipt);
