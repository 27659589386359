import React from "react";
import PropTypes from "prop-types";

import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import SplitBillByItem from "@components/split-bill-by-item";
import SplitBillByAmount from "@components/split-bill-by-amount";
import SplitBillByEqualShare from "@components/split-bill-by-equal-share";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import langFn from "@util/lang";

import { useSelector } from "react-redux";

function Overview({ toggleCheckoutForm, title, style }) {
  const { totalAmount } = useSelector((state) => state?.order);
  const {
    outlet: { currencySymbol },
  } = useSelector((state) => state?.bill?.bill);
  return (
    <div style={{ paddingTop: 10 }}>
      <Accordion style={style} onChange={() => toggleCheckoutForm(false)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid item xs={9}>
              <Typography variant="h3">
                <strong>{title}</strong>
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="h3" align="right">
                <strong>
                  {currencySymbol} {totalAmount}
                </strong>
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}

const addButtonStyle = {
  borderRadius: "50%",
  padding: 0,
  height: 45,
  width: 45,
  minWidth: "unset",
};

const persons = [
  {
    name: "Maria",
    amount: "10",
    paid: "",
    active: true,
  },
  {
    name: "John",
    amount: "5",
    paid: true,
  },
  {
    name: "Lee",
    amount: "5",
    paid: "",
  },
];

function TabPanel({
  title,
  amount,
  checkoutFormStatus,
  toggleCheckoutForm,
  ...props
}) {
  const { children, value, index, ...other } = props;

  const Wrapper = ({ children }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );

  const style = {
    boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
    borderRadius: 20,
  };

  if (checkoutFormStatus) {
    return (
      <Wrapper>
        {value === index && (
          <Overview
            toggleCheckoutForm={toggleCheckoutForm}
            title={title}
            style={style}
          />
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {value === index && <div style={{ paddingTop: 10 }}>{children}</div>}
    </Wrapper>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "70%",
    margin: "0 auto",
    borderRadius: 7,
    "& .Mui-selected": {
      backgroundColor: "#000 !important",
      color: "#fff !important",
      borderRadius: 7,
      zIndex: 2,
    },
    "& .MuiTab-root": {
      borderRadius: 7,
      backgroundColor: "#EFF1F1",
      minHeight: 33,
      height: 33,
      color: "#B8BABA",
      textTransform: "capitalize",
    },
    "& .MuiTabs-scroller": {
      paddingBottom: 2,
      height: 33,
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const calculateSplitAmount = (amount, numberOfPeople) => {
  if (!Boolean(numberOfPeople)) {
    return amount.toFixed(2);
  }

  const splitAmount = amount / numberOfPeople;
  return splitAmount.toFixed(2);
};

function FullWidthTabs({
  amount,
  updateAmount,
  localAmount,
  classes,
  ...props
}) {
  const tabsClasses = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [amountSplitType, setAmountSplitType] = React.useState(1);
  const [numberOfPeople, setNumberOfPeople] = React.useState(0);

  // React.useEffect(() => {
  //   console.log(value);
  //   console.log(props);
  // });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const updateTipAmount = () => {
    updateAmount(calculateSplitAmount(24, numberOfPeople));
    // setLocalAmount(calculateSplitAmount(localAmount, numberOfPeople));
  };

  // React.useEffect(() => {
  //   updateAmount(calculateSplitAmount(amount, numberOfPeople));
  // }, [numberOfPeople]);

  return (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
        className={tabsClasses.root}
        centered
      >
        <Tab
          label={langFn("By amount")}
          {...a11yProps(0)}
          className={classes.leftTabButton}
        />
        <Tab
          label={langFn("By Items")}
          {...a11yProps(1)}
          className={classes.rightTabButton}
        />
      </Tabs>

      <TabPanel
        {...props}
        value={value}
        index={0}
        dir={theme.direction}
        title={
          !amountSplitType
            ? langFn("Split by an amount")
            : langFn("Split by equal share")
        }
        amount={7.0}
      >
        {amountSplitType === 1 ? (
          <React.Fragment>
            <SplitBillByEqualShare {...props} />
            <Typography
              variant="subtitle1"
              className={classes.link}
              onClick={(e) => setAmountSplitType(2)}
            >
              {langFn("Want to pay a different amount")}?
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SplitBillByAmount {...props} />

            <Typography
              variant="subtitle1"
              className={classes.link}
              onClick={(e) => setAmountSplitType(1)}
            >
              {langFn("Want to split by equal share")}?
            </Typography>
          </React.Fragment>
        )}
      </TabPanel>
      <TabPanel
        {...props}
        value={value}
        index={1}
        dir={theme.direction}
        title={langFn("Split by items")}
        amount={7.0}
      >
        <SplitBillByItem {...props} />
      </TabPanel>
    </React.Fragment>
  );
}

const style = (theme) => ({
  leftTabButton: {
    position: "absolute",
    left: 0,
    width: "55%",
    boxShadow:
      "rgb(0 0 0 / 20%) 7px -1px 3px -2px, rgb(0 0 0 / 0%) 0px 3px 4px 0px, rgb(0 0 0 / 0%) 0px 1px 8px 0px",
  },
  rightTabButton: {
    position: "absolute",
    right: 0,
    width: "55%",
    boxShadow:
      "rgb(0 0 0 / 20%) -2px -1px 3px 3px, rgb(0 0 0 / 0%) 0px 3px 4px 0px, rgb(0 0 0 / 0%) 0px 1px 8px 0px",
  },
  link: {
    color: theme.palette.success.main,
    textDecoration: "underline",
    cursor: "pointer",
    letterSpacing: "0.02em",
    textUnderlineOffset: "4px",
    paddingTop: 6,
  },
});

export default withStyles(style)(FullWidthTabs);
