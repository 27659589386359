import { action } from "./reducer";
import { action as itemsAction } from "../items/reducer";
import { OrderService } from "../../services";

const { updateTipAmount, updateTotalAmount, updateIamPaying } = action;

const dummyData = [
  {
    name: "Spicy Mixed Olives",
    quantity: 1,
    price: 3.5,
    category: "starter",
  },
  {
    name: "Halloumi Sticks",
    quantity: 1,
    price: 4,
    category: "starter",
  },
  {
    name: "Sunset Burger",
    quantity: 1,
    price: 8.25,
    category: "main",
  },
  {
    name: "Chicken Butterfly",
    quantity: 1,
    price: 8.25,
    category: "main",
  },
];

export const getOrderDetails = (params) => async (dispatch) => {
  dispatch(itemsAction.status("loading"));
  return OrderService.load(params)
    .then(
      (response) =>
        dispatch(itemsAction.updateItem(dummyData)) &&
        dispatch(itemsAction.status(null))
    )
    .catch(
      (error) =>
        dispatch(itemsAction.error(error)) && dispatch(itemsAction.status(null))
    );
};

export default {
  updateTotalAmount,
  updateTipAmount,
  updateIamPaying,
  getOrderDetails,
};

// Dummy data
