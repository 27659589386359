import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import FooterLogo from "@components/footer-logo";

function LogoLayout({ children }) {
  return (
    <Grid
      container
      alignContent="space-between"
      style={{
        width: "100%",
        height: "100%",
        padding: "0 4px 0px 4px",
      }}
      className="logo-layout"
    >
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <FooterLogo />
      </Grid>
    </Grid>
  );
}

LogoLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LogoLayout;
