import { createSelector } from "@reduxjs/toolkit";

export const UISelector = (state) => state.ui;

export const getVersion = createSelector(UISelector, (state) => state.version);

export const getLogo = createSelector(UISelector, (state) => state.logo);

export const getBrandingLogo = createSelector(
  UISelector,
  (state) => state.brandingLogo
);

export const checkoutFormStatus = createSelector(
  UISelector,
  (state) => state.showCheckoutForm
);

export const splitBillStatus = createSelector(
  UISelector,
  (state) => state.showSplitBill
);

export const getTipsButtonEnabledStatus = createSelector(
  UISelector,
  (state) => state.tipsButtonEnabled
);

export const getCustomTipAmountStatus = createSelector(
  UISelector,
  (state) => state.customTipAmount
);

export const getPaymentMode = createSelector(
  UISelector,
  (state) => state.paymentMode
);

export default UISelector;
