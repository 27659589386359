import APIService from "./APIService";

export default class GraphqlService extends APIService {
  static get(query, variable) {
    const { operationName } = query;
    const timeStamp = new Date().getUTCMilliseconds();
    const queryParameter = this.buildQuery({ operationName, timeStamp });

    return this.post(`/graphql${queryParameter}`, {
      body: JSON.stringify({ query, ...variable }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
