import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tips from "@components/tips";
import orderAction from "../../store/order/api";
import * as orderSelector from "../../store/order/selector";
import * as uiSelector from "../../store/ui/selector";
import * as tipsSelector from "../../store/tips/selector";
import { action as UIAction } from "@store/ui/reducer";

const mapStateToProps = (state) => ({
  orderDetail: orderSelector.default(state),
  tipsButtonEnabled: uiSelector.getTipsButtonEnabledStatus(state),
  customTipAmount: uiSelector.getCustomTipAmountStatus(state),
  totalAmount: orderSelector.getTotalAmount(state),
  appSettings: tipsSelector.getTips(state),
});

const mapDispatchToProps = {
  updateTipAmount: orderAction.updateTipAmount,
  updateTipsButtonEnabled: UIAction.updateTipsButtonEnabled,
  updateCustomTipAmount: UIAction.updateCustomTipAmount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Tips));
