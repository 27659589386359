import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Tabs, Tab, withStyles } from "@material-ui/core";
import PaymentOverview from "../split-bill-overview";
import Content from "./content";
import theme from "@root/theme";
import { useSelector } from "react-redux";
import langFn from "@util/lang";

const buttonActive = {
  variant: "contained",
  color: "primary",
  style: { color: "#fff", backgroundColor: "#000" },
};

function SplitBill({ amount, updateAmount, classes, ...props }) {
  const [value, setValue] = React.useState(false);
  const [localAmount, setLocalAmount] = React.useState(0);
  const { outlet = {} } = useSelector((state) => state.bill?.bill);
  const { currencySymbol } = outlet;

  React.useEffect(() => setLocalAmount(amount), []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleOptions = () => {
    if (value) {
      updateAmount(localAmount);
    }

    const openCheckoutForm = value;
    props.toggleCheckoutForm(openCheckoutForm);
    props.toggleSplitBill(!value);
    setValue(!value);
  };

  const isActive = value ? buttonActive : { style: { color: "#000" } };

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid xs={12} item container justifyContent="center">
        <Button
          color="secondary"
          variant="contained"
          className={classes.splitBill}
          {...isActive}
          onClick={() => toggleOptions()}
        >
          {langFn("Split The Bill")}
        </Button>
      </Grid>

      {value && (
        <Grid item xs={12}>
          <Content
            amount={amount}
            updateAmount={updateAmount}
            localAmount={localAmount}
            currencySymbol={currencySymbol}
            {...props}
          />
        </Grid>
      )}
      {value && (
        <Grid item xs={12}>
          <PaymentOverview currencySymbol={currencySymbol} />
        </Grid>
      )}
    </Grid>
  );
}

SplitBill.propTypes = {};

const Style = (theme) => ({
  splitBill: {
    borderRadius: 10,
    backgroundColor: "#EFF1F1",
    color: "#000",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.13)",
    padding: "9px 39px",
    fontWeight: "600",
  },
});

export default withStyles(Style)(SplitBill);
