import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import StripePayment from "./StripePayment";

import * as orderSelector from "../../store/order/selector";
import * as billSelector from "../../store/bill/selector";
import * as uiSelector from "../../store/ui/selector";
import { action as UIAction } from "@store/ui/reducer";

const mapStateToProps = (state) => {
  return {
    orderDetail: orderSelector.default(state),
    checkoutFormStatus: uiSelector.checkoutFormStatus(state),
    showSplitBill: uiSelector.splitBillStatus(state),
    activePaymentMode: uiSelector.getPaymentMode(state),
    bill: billSelector.getBill(state),
  };
};

const mapDispatchToProps = {
  toggleCheckoutForm: UIAction.toggleCheckoutForm,
  togglePaymentMode: UIAction.togglePaymentMode,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StripePayment));
