import { action as billAction } from "./reducer";
import { action as orderAction } from "../order/reducer";
import { action as tipsAction } from "../tips/reducer";

import getBill from "@root/graphql/bill/hooks/getBill";
import { isLoading } from "./selector";

export const getBillDetails = (params) => (dispatch) => {
  dispatch(billAction.isLoading(true));

  return getBill(params)
    .then((res) => {
      dispatch(billAction.updateBill(res));
      dispatch(orderAction.updateTotalAmount(res?.totalAmount));
      dispatch(tipsAction.updateAppSetting(res?.outlet));
    })
    .catch((error) => dispatch(billAction.error(error)))
    .finally(() => dispatch(billAction.isLoading(false)));
};

export default {
  getBillDetails,
};

// Dummy data
