import React from "react";
import PropTypes from "prop-types";
import TextField from "@components/text-field";
import MaskedInput from "react-text-mask";
import { useSelector } from "react-redux";

function CardNumberMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u005F"}
      keepCharPositions
      guide={false}
    />
  );
}

function CurrencyTextField({ currencySymbol, ...props }) {
  const onChange = ({ target: { value } }) => {
    if (props.onChange) {
      const number = value
        ? parseFloat(value.replace(currencySymbol, ""))
        : value;
      props.onChange({ target: { value: number } });
    }
  };

  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: CardNumberMask,
      }}
      onChange={onChange}
    />
  );
}

CurrencyTextField.propTypes = {};

export default CurrencyTextField;
