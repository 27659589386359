/* eslint-disable yoda */
import fetch from "unfetch";
import { token } from "./token";

export default class APIService {
  // static get AuthService() {
  //   return AuthService
  // }

  static get $fetch() {
    return fetch;
  }

  static get fetch() {
    return this.$fetch;
  }

  static get version() {
    return "v1";
  }

  static get api() {
    return `${process.env.API_URL}`;
  }

  static get defaultHeaders() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // if (this.AuthService.isAuthorized()) {
    //   headers.Authorization = `Bearer ${this.AuthService.getToken()}`
    // }

    headers.Authorization = token;

    return headers;
  }

  static async getResponseError(response) {
    let payload;
    // can't get response.status to redirect on unauth
    if (response.status === 401) {
      //AuthService.userUnauthorised()
    }

    try {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        payload = await response.json();
      } else {
        payload = await response.text();
      }
    } catch (err) {
      payload = response.statusText || response.message;
    }

    // throw payload;
  }

  static isSuccessResponse(response) {
    return response.status >= 200 && response.status < 300;
  }

  static async getResponseData(response) {
    if (!APIService.isSuccessResponse(response)) {
      throw response;
    }
    const responseText = await response.text();
    if (responseText.length === 0 || responseText === "OK") {
      return Promise.resolve({});
    }

    return JSON.parse(responseText);
  }

  static getSearchParams(params) {
    const searchParams = Object.keys(params).reduce((acc, key) => {
      if (typeof params[key] !== "undefined") {
        acc[key] = params[key];
      }

      return acc;
    }, {});

    return new URLSearchParams(searchParams);
  }

  static buildQuery(params, prefix = "?") {
    const searchParams = this.getSearchParams(params);
    const query = searchParams.toString();
    if (query) {
      return `${prefix}${query}`;
    }

    return "";
  }

  static buildLowerCaseQuery(params) {
    return Object.keys(params).reduce((acc, key) => {
      if (typeof params[key] !== "undefined") {
        const lowerCaseKeys = String(key).toLowerCase();
        acc[lowerCaseKeys] = params[key];
      }
      return acc;
    }, {});
  }

  static getHeaders(options) {
    return Object.assign(this.defaultHeaders, options.headers);
  }

  static get(url, options = {}) {
    return this.fetch(`${this.api}${url}`, {
      ...options,
      method: "GET",
      headers: this.getHeaders(options),
    });
  }

  static post(url, options = {}) {
    return this.fetch(`${this.api}${url}`, {
      ...options,
      method: "POST",
      headers: this.getHeaders(options),
    });
  }

  static delete(url, options = {}) {
    return this.fetch(`${this.api}${url}`, {
      ...options,
      method: "DELETE",
      headers: this.getHeaders(options),
    });
  }

  static put(url, options = {}) {
    return this.fetch(`${this.api}${url}`, {
      ...options,
      method: "PUT",
      headers: this.getHeaders(options),
    });
  }

  static patch(url, options = {}) {
    return this.fetch(`${this.api}${url}`, {
      ...options,
      method: "PATCH",
      headers: this.getHeaders(options),
    });
  }
}
