import { createSlice } from "@reduxjs/toolkit";

const items = createSlice({
  name: "tips",
  initialState: {
    appSettings: {},
    isLoading: null,
    error: null,
  },
  reducers: {
    updateAppSetting: (state, { payload }) => {
      // console.log(payload);
      return {
        ...state,
        appSettings: payload?.appSettings,
      };
    },
    status: (state, { payload }) => ({ ...state, isLoading: payload }),
    error: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const action = items.actions;

export default items;
