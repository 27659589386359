import { createSlice } from "@reduxjs/toolkit";

const feedback = createSlice({
  name: "feedback",
  initialState: {
    averageRating: 0,
    feedbackSubmitted: false,
    additionalFeedbackSubmitted: false,
  },
  reducers: {
    updateAverageRating: (state, { payload }) => ({
      ...state,
      averageRating: payload,
    }),
    updateFeedbackSubmitted: (state, { payload }) => ({
      ...state,
      feedbackSubmitted: payload,
    }),
    updateAdditionalFeedbackSubmitted: (state, { payload }) => ({
      ...state,
      additionalFeedbackSubmitted: payload,
    }),
  },
});

export const action = feedback.actions;

export default feedback;
