import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";

import { action as paymentAction } from "@store/payment/reducer";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ButtonShadowed = withStyles((theme) => ({
  contained: {
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);",
  },
}))(Button);

const StripePaymentButton = ({ clientSecret, device, children }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [loading, setLoading] = useState();
  const history = useHistory();
  const { tableId } = useParams();

  const dispatch = useDispatch();

  const { totalAmount = 0, tipAmount = 0 } = useSelector(
    (state) => state.order
  );

  const { billNumber = "", outlet = {} } = useSelector(
    (state) => state?.bill?.bill
  );
  const { id, currencySymbol } = outlet;

  let netTotal = tipAmount + totalAmount;

  // This is to fix strange js problem of multiplying by 100
  // For description of problem please visit
  // https://stackoverflow.com/questions/21472828/javascript-multiplying-by-100-giving-weird-result

  netTotal = netTotal * 10 * 10;

  // END calculation

  netTotal = parseInt(netTotal);

  const setMessage = (message) => dispatch(paymentAction.addMessage(message));
  const setIsLoading = (state) => dispatch(paymentAction.setIsLoading(state));

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "Restaurant bill",
          amount: netTotal,
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });
      setLoading(true);
      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          // console.log(result);
          if (result) {
            setPaymentRequest(pr);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [stripe, netTotal, clientSecret]);

  const redirectForFeedback = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      history.push(`/payment-success/${tableId}/${id}/${billNumber}`);
    }, 3000);

    //history.push(`/payment-success/${tableId}/${id}/${billNumber}`);
  };

  const onPaymentMethod = (ev) => {
    return redirectForFeedback();
    // Confirm the PaymentIntent without handling potential next actions (yet).
    stripe
      .confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      )
      .then((confirmResult) => {
        if (confirmResult.error) {
          setMessage(confirmResult.message);
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          redirectForFeedback();
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (confirmResult.paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            stripe.confirmCardPayment(clientSecret).then((result) => {
              if (result.error) {
                console.log(result.error);
                setMessage(result.message);
                // The payment failed -- ask your customer for a new payment method.
              } else {
                console.log("success");
                redirectForFeedback();
                // The payment has succeeded.
              }
            });
          } else {
            console.log("success");
            redirectForFeedback();
            // The payment has succeeded.
          }
        }
      });
  };

  const openPaymentMethod = () => {
    if (paymentRequest) {
      setIsLoading(true);
    }
    return !paymentRequest ? false : paymentRequest.show();
  };

  const notAllowed = () =>
    setMessage(
      "Currently this payment method is not supported by your browser"
    );

  if (loading) {
    return (
      <ButtonShadowed
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        style={{
          fontSize: 20,
          fontWeight: "bold",
          borderRadius: 10,
        }}
        elevation={5}
      >
        <CircularProgress color="secondary" />
      </ButtonShadowed>
    );
  }

  // Hotfix due to on going stripe issue that it show link payment method by default
  return (
    <span onClick={redirectForFeedback}>{children}</span>

    // <Button style={{ width: "100%", height: "100%" }} onClick={notAllowed}>
    //   <img
    //     src={`assets/images/${device}-pay.svg`}
    //     style={{ height: 23 }}
    //     alt="payment-button"
    //   />
    // </Button>
  );

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", onPaymentMethod);
    paymentRequest.on("cancel", () => setIsLoading(false));
    return (
      <span onClick={openPaymentMethod}>{children}</span>
      // <Button
      //   style={{ width: "100%", height: "100%" }}
      //   onClick={openPaymentMethod}
      // >
      //   <img
      //     src={`assets/images/${device}-pay.svg`}
      //     style={{ height: 23 }}
      //     alt="payment-button"
      //   />
      //   {/* <PaymentRequestButtonElement options={{ paymentRequest }} /> */}
      // </Button>
    );
    // return (
    //   <PaymentRequestButtonElement
    //     options={{ paymentRequest, ...buttonStyle }}
    //   />
    // );
  }
};

export default StripePaymentButton;
