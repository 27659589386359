import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

function CheckedIcon({ icons, classes, ...props }) {
  return (
    <div className={classes.wrapper}>
      <CheckIcon className={classes.icons} />
    </div>
  );
}

CheckedIcon.propTypes = {};

const Styles = (theme) => ({
  wrapper: {
    position: "relative",
    height: 20,
    width: 20,
    boxShadow: "0px 0px 5px 2px rgba(139, 139, 139, 0.3)",
    borderRadius: 5,
  },

  icons: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: "100%",
    color: "green",
  },
  icons1: {
    boxShadow: "0px 0px 5px 2px rgba(139, 139, 139, 0.3)",
    color: "#fff",
  },
});

export default withStyles(Styles)(CheckedIcon);
