import React from "react";
import PropTypes from "prop-types";
import Icons from "@components/icons";
import { Typography, Box } from "@material-ui/core";
import langFn from "@util/lang";

const Light = ({ text, ...props }) => (
  <Box position="relative" component="span" {...props}>
    {langFn(text)}
    <Box
      position="absolute"
      component="span"
      bottom={-24}
      left={-24}
      width={60}
      textAlign="left"
    >
      <Icons variant="zigzagLight" style={{ width: "100%", height: 24 }} />
    </Box>
  </Box>
);

const Disabled = ({ text, ...props }) => (
  <Box position="relative" component="span" {...props}>
    {langFn(text)}
    <Box
      position="absolute"
      component="span"
      bottom={-24}
      left={-55}
      width={118}
      textAlign="left"
    >
      <Icons variant="zigzagDisabled" style={{ width: "100%", height: 24 }} />
    </Box>
  </Box>
);

const Dark = ({ text, ...props }) => (
  <Box position="relative" component="span" {...props}>
    {langFn(text)}
    <Box
      position="absolute"
      component="span"
      bottom={-15}
      left={0}
      width="100%"
      textAlign="left"
    >
      <Typography align="center">
        <img src="/assets/images/zigzag-dark.png" alt="zigzag-dark" />
      </Typography>
    </Box>
  </Box>
);

function ZigZagText(props) {
  if (props.variant === "light") {
    return <Light {...props} />;
  }

  if (props.variant === "disabled") {
    return <Disabled {...props} />;
  }

  return <Dark {...props} />;
}

ZigZagText.propTypes = {};

export default ZigZagText;
