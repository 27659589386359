import React from "react";
import PropTypes from "prop-types";
import { Slider, Typography, Box, withStyles } from "@material-ui/core";
import "./style.css";

function valuetext(value) {
  return `${value}°C`;
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

function SliderInput({ title, marks, defaultValue, classes, ...props }) {
  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-restrict" variant="h2" gutterBottom>
        <Box fontSize={18} fontWeight={500}>
          {title}
        </Box>
      </Typography>
      <Slider
        component="div"
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-restrict"
        step={10}
        scale={(value) => value * 10}
        valueLabelDisplay="off"
        marks={marks}
        {...props}
        defaultValue={defaultValue * 10}
        track={false}
      />
    </div>
  );
}

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "1",
  },
  {
    value: 20,
    label: "2",
  },
  {
    value: 30,
    label: "3",
  },
  {
    value: 40,
    label: "4",
  },
  {
    value: 50,
    label: "5",
  },
  {
    value: 60,
    label: "6",
  },
  {
    value: 70,
    label: "7",
  },
  {
    value: 80,
    label: "8",
  },
  {
    value: 90,
    label: "9",
  },
  {
    value: 100,
    label: "10",
  },
];

const trackColor = "#E9E9E9";

const Style = () => ({
  root: {
    "& .MuiSlider-root": {
      marginBottom: 0,
      marginTop: 30,
      "& .MuiSlider-markLabelActive": {
        "&:last-of-type": {
          color: "red",
        },
      },
    },
    "& .MuiSlider-rail": {
      height: 6,
      backgroundColor: trackColor,
      borderRadius: 100,
      opacity: 1,
    },
    "& .MuiSlider-track": {
      backgroundColor: trackColor,
    },
    "& .MuiSlider-mark": {
      backgroundColor: trackColor,
    },
    "& .MuiSlider-markLabel": {
      top: -18,
      fontSize: 14,
      color: "#E9E9E9",
    },
    "& .MuiSlider-markLabelActive": {
      fontSize: 18,
      lineHeight: 1,
      color: "#000",
    },
    "& .MuiSlider-thumb": {
      "& .MuiSlider-active": {
        boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 16%)",
      },
      backgroundColor: "#424242",
      // from 12px(default size) to 38px(design size) = increase 216 %
      transform: "scale(3.2)",
    },
  },
});

SliderInput.propTypes = {
  marks: PropTypes.object,
  defaultValue: PropTypes.number,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

Slider.defaultProps = {
  marks,
  defaultValue: 10,
};

export default withStyles(Style)(SliderInput);
