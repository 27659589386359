import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Error from "./Error";

function RouteWrapper({ layout: Layout, ...props }) {
  if (!Layout) {
    return <>{<Route {...props} />}</>;
  }
  return (
    <Layout>
      <Error>
        <Route {...props} />
      </Error>
    </Layout>
  );
}

RouteWrapper.propTypes = {
  layout: PropTypes.func,
};

RouteWrapper.defaultProps = {
  layout: null,
};

export default RouteWrapper;
