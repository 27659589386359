import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { action as paymentAction } from "@store/payment/reducer";
import { useDispatch } from "react-redux";

const buttons = {
  isCardActive: "/assets/images/button/active-credit-card.svg",
  isCardInActive: "/assets/images/button/inactive-credit-card.svg",
  isGooglePayInActive: "/assets/images/button/inactive-google-pay.svg",
  isGooglePayActive: "/assets/images/button/active-google-pay.svg",
  isApplePayInActive: "/assets/images/button/inactive-apple-pay.svg",
  isApplePayActive: "/assets/images/button/active-apple-pay.svg",
};
function PaymentMethodButton({ device, togglePaymentMode, activePaymentMode }) {
  const cardButton = activePaymentMode
    ? buttons?.isCardActive
    : buttons?.isCardInActive;
  const googlePay = !activePaymentMode
    ? buttons?.isGooglePayActive
    : buttons?.isGooglePayInActive;
  const applePay = !activePaymentMode
    ? buttons?.isApplePayActive
    : buttons?.isApplePayInActive;

  const dispatch = useDispatch();

  // return (
  //   <div style={{ position: "relative", height: 80, width: "100%" }}>
  //     <Button
  //       style={{
  //         width: 165,
  //         height: 71,
  //         padding: 0,
  //         borderRadius: "14px",
  //       }}
  //     >
  //       <img src={cardButton} />
  //     </Button>
  //     <Button
  //       style={{
  //         width: 165,
  //         height: 71,
  //         padding: 0,
  //         borderRadius: "14px",
  //       }}
  //     >
  //       <img
  //         src={device === "apple" ? applePay : googlePay}
  //         alt="payment-button"
  //       />
  //     </Button>
  //   </div>
  // );

  // In javascript if we add number and bool true
  //  100 + true = 101
  //  100 + false = 100

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <div style={{ position: "relative", height: 71, width: "296px" }}>
          <Box
            width="45%"
            height="71px"
            color="white"
            position="absolute"
            borderRadius="14px"
            top={0}
            left="-2%"
            zIndex={1300 + activePaymentMode}
            onClick={() => {
              togglePaymentMode(true);
              dispatch(paymentAction.addMessage(""));
            }}
          >
            <Button
              style={{
                width: 165,
                height: 71,
                padding: 0,
                borderRadius: "14px",
              }}
            >
              <img src={cardButton} style={{ height: 71 }} />
            </Button>
          </Box>
          <Box
            width="45%"
            height="71px"
            color="text.primary"
            position="absolute"
            top={0}
            right="10%"
            zIndex={1300 + !activePaymentMode}
            borderRadius="14px"
            onClick={() => {
              togglePaymentMode(false);
              dispatch(paymentAction.addMessage(""));
            }}
          >
            <Button
              style={{
                width: 165,
                height: 71,
                padding: 0,
                borderRadius: "14px",
              }}
            >
              <img
                src={device === "apple" ? applePay : googlePay}
                style={{ height: 71 }}
                alt="payment-button"
              />
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}

PaymentMethodButton.propTypes = {};

export default PaymentMethodButton;
