import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import Typography from "@components/typography";
import MUIRating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";
import Card from "@components/card";

//Rating Icons
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import StarRoundedIcon from "@material-ui/icons/StarRounded";

// const Rating = withStyles((theme) => ({
//   root: {
//     "& .MuiRating-label:nth-child(1)": {
//       color: "#fff",
//     },
//     "& .MuiRating-label:nth-child(2)": {
//       color: "#A9A9A9",
//     },
//     "& .MuiRating-label:nth-child(3)": {
//       color: "#797979",
//     },
//     "& .MuiRating-label:nth-child(4)": {
//       color: "#4D4D4D",
//     },
//     "& .MuiRating-label:nth-child(5)": {
//       color: "#000000",
//     },
//   },
// }))(MUIRating);

const Rating = withStyles({
  root: {
    display: "flex",
    justifyContent: "space-evenly",
    "& .MuiRating-pristine": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 30,
    },
  },
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#34A853",
  },
  iconEmpty: {
    color: "#37b548",
  },
})(MUIRating);

const customIcons = {
  1: {
    icon: <StarRoundedIcon style={{ color: "#CEE6D4" }} />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <StarRoundedIcon style={{ color: "#9ADFAC" }} />,
    label: "Dissatisfied",
  },
  3: {
    icon: <StarRoundedIcon style={{ color: "#6DD88A" }} />,
    label: "Neutral",
  },
  4: {
    icon: <StarRoundedIcon style={{ color: "#39D563" }} />,
    label: "Satisfied",
  },
  5: {
    icon: <StarRoundedIcon style={{ color: "#34A853" }} />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;

  const classes = props.className;

  const checked = classes
    ? String(classes)
        .split(" ")
        .includes("MuiRating-iconFilled")
    : false;

  if (checked) {
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  return (
    <span {...other}>
      <StarOutlineRoundedIcon />
    </span>
  );
}

function RatingFeed({ classes, name, onChange, ...props }) {
  const [value, setValue] = React.useState(0);
  React.useEffect(
    () => {
      onChange({ target: { name, value } });
    },
    [value]
  );

  return (
    <Rating
      name={name}
      size="large"
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      getLabelText={(value) => customIcons[value]?.label}
      IconContainerComponent={IconContainer}
    />
  );
}

Rating.propTypes = {};

export default RatingFeed;
