import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

function PaymentMessage(props) {
  const { message } = useSelector((state) => state.payment);
  return (
    <Typography variant="h4" align="center" style={{ color: "#FF0000" }}>
      {message}
    </Typography>
  );
}

PaymentMessage.propTypes = {};

export default PaymentMessage;
