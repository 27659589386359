import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@material-ui/core";
import Typography from "@components/typography";
import { withStyles } from "@material-ui/core/styles";
import Card from "@components/card";
import Rating from "@components/rating";

function RatingWrapper({ classes, onChange, ...props }) {
  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs="3" className={classes.verticalAlign}>
          <Typography variant="h3" type="medium">
            Food
          </Typography>
        </Grid>
        <Grid item xs="9">
          <Rating name="food" onChange={onChange} />
        </Grid>
        <Grid item xs="12">
          <Divider />
        </Grid>
        <Grid item xs="3" className={classes.verticalAlign}>
          <Typography variant="h3" type="medium">
            Service
          </Typography>
        </Grid>
        <Grid item xs="9">
          <Rating name="service" onChange={onChange} />
        </Grid>
        <Grid item xs="12">
          <Divider />
        </Grid>
        <Grid item xs="3" className={classes.verticalAlign}>
          <Typography variant="h3" type="medium">
            Hygiene
          </Typography>
        </Grid>
        <Grid item xs="9">
          <Rating name="hygiene" onChange={onChange} />
        </Grid>
      </Grid>
    </Card>
  );
}

Rating.propTypes = {};

const Style = (theme) => ({
  verticalAlign: {
    display: "flex",
    alignItems: "center",
  },
});

export default withStyles(Style)(RatingWrapper);
