import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BillSummary from "./BillSummary";
import billAPI from "../../store/bill/api";
import * as billSelector from "../../store/bill/selector";

//import * as orderSelector from "../../store/order/selector";
// import * as billSelector from "../../store/items/selector";

const mapStateToProps = (state) => {
  console.log(state);
  return {
    bill: billSelector.getBill(state),
    items: billSelector.getItems(state),
    error: billSelector.getErrors(state),
    isLoading: billSelector.isLoading(state),
  };
};

const mapDispatchToProps = {
  getBillDetails: billAPI.getBillDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BillSummary));
