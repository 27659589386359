/* eslint-disable no-unused-vars */
import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Container } from "@material-ui/core";
import OrientationWarning from "@components/orientation-warning";
import SocketClient from "./services/Socket";
import { updateName, disconnect, subscribeToRoom } from "./store/splitBill/api";
import { useDispatch, useSelector } from "react-redux";

import Routes from "./route";
import logo from "./logo.svg";
import "./App.css";

const checkerDivStyle = {
  position: "fixed",
  top: 0,
  background: "aqua",
  width: "50px",
  zIndex: 999999,
  left: "48px",
  height: 40,
  display: "none",
};
// const socketClient = new SocketClient();
// socketClient.connect().then(console.log);

export const history = createBrowserHistory();

function App() {
  // React.useEffect(() => {
  //   subscribeToRoom("demo-room");
  // }, []);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.splitBill);
  return (
    // <div>
    //   <input
    //     onChange={({ target: { value } }) => dispatch(updateName(value))}
    //     name="name"
    //     type="text"
    //   />

    //   <div>{JSON.stringify(state)}</div>
    //   <button onClick={() => disconnect()}>Close</button>
    //   <button onClick={() => subscribeToRoom()}>Subscribe to room</button>
    // </div>
    <React.Fragment>
      <div style={checkerDivStyle}>width measurement bar </div>
      <Container>
        <div className="portrait">
          <Router history={history}>
            <Route path="/" component={Routes} />
          </Router>
        </div>
        <div className="landscape">
          <OrientationWarning />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default App;
