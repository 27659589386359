import React from "react";
import PropTypes from "prop-types";
import langFn from "@util/lang";

import { Grid, Typography, withStyles } from "@material-ui/core";

function NoBillFound({ classes }) {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={4}
      style={{ paddingTop: "61%" }}
    >
      <Grid xs="12" item>
        <Typography align="center">
          <img
            src="/assets/images/logo2.svg"
            alt="fetch logo"
            style={{ height: "3rem" }}
          />
        </Typography>
      </Grid>
      <Grid xs="12" item>
        <Typography align="center" className={classes.hello}>
          {langFn("Hello")}.
        </Typography>
      </Grid>
      <Grid xs="12" item>
        <Typography align="center" className={classes.desc}>
          {langFn("This table does not have a bill yet")}.{" "}
          {langFn("Please check with the staff")}.
        </Typography>
      </Grid>
    </Grid>
  );
}

const Styles = {
  hello: {
    fontSize: 25,
    fontWeight: "medium",
  },
  desc: {
    color: "#8E8E8E",
    fontSize: 18,
  },
};

NoBillFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(NoBillFound);
