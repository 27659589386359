import React from "react";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import TextField from "@components/text-field";
function FeedbackInput({ classes, onFeedbackSubmit, onChange, feedbackError }) {
  return (
    <Grid xs={12} item container justifyContent="center">
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <TextField
          label="We’d love your feedback!"
          multiline
          fullWidth
          maxRows={6}
          minRows={6}
          className={classes.inputLabel}
          name="feedback"
          onChange={onChange}
        />
      </Grid>

      <Grid xs="12">
        <p style={{ color: "red", textAlign: "center" }}>
          {" "}
          {feedbackError ? "Please fill all fields in feedback" : null}
        </p>
      </Grid>

      <Grid xs={5}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={onFeedbackSubmit}
          className={classes.button}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

FeedbackInput.propTypes = {
  onFeedbackSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FeedbackInput;
