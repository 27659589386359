import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import TextField from "@components/text-field";
import { formatToCurrency } from "@utils/currency";
import ZigZagText from "@components/zig-zag-text";
import CurrencyTextField from "@components/currency-text-field";
import Typography from "@components/typography";
import langFn from "@util/lang";

function SplitBillByAmount({ classes, updateIamPaying, ...props }) {
  const [paying, setIamPaying] = React.useState();
  const [name, setName] = React.useState("");
  const [error, showError] = React.useState(false);

  const totalAmount = 24;

  const remainingTotal = totalAmount - (paying || 0);

  React.useEffect(() => {
    if (paying && !name) {
      showError(true);
    } else {
      showError(false);
    }

    if (paying && name && paying <= totalAmount) {
      props.updateTotalAmount(paying);
      updateIamPaying(paying);
    } else {
      if (paying > totalAmount) {
        props.updateTotalAmount(totalAmount);
      }
    }
  }, [paying, name]);

  return (
    <Grid container spacing={2} className={classes.wrapper}>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          weight="semiBold"
          className={classes.letterSpacing}
          gutterBottom
        >
          <strong>{langFn("Split by an amount")}</strong>
        </Typography>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.letterSpacing}
        >
          {langFn("Split the bill equally between your friends/family")}.{" "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {error && (
          <Typography variant="h5" color="error" align="center" gutterBottom>
            {langFn("Please enter name")}
          </Typography>
        )}
        <TextField
          placeholder={langFn("Your name")}
          fullWidth
          size="small"
          value={name}
          className={classes.textCenter}
          onChange={({ target: { value } }) => setName(value)}
        />
      </Grid>
      <Grid item xs={12}>
        {""}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          weight="medium"
          align="center"
          className={classes.fontWeight}
        >
          {langFn("Balance Due")}{" "}
          <ZigZagText
            text={
              <strong>
                {props.currencySymbol} {formatToCurrency(remainingTotal)}
              </strong>
            }
            variant="dark"
            className={classes.biggerFont}
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {" "}
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h2"
          weight="medium"
          align="center"
          className={classes.letterSpacing}
        >
          {langFn("I am paying")}
        </Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={4}>
          <CurrencyTextField
            placeholder={`${props.currencySymbol} 0.00`}
            size="small"
            value={paying}
            className={classes.textCenter}
            onChange={({ target: { value } }) => setIamPaying(value)}
            currencySymbol={props.currencySymbol}
          />
        </Grid>
        {remainingTotal < 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" color="error" align="center">
              {langFn("Cannot pay more than total amount")}({" "}
              {props.currencySymbol} {formatToCurrency(totalAmount)} )
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

SplitBillByAmount.propTypes = {};

const styles = (theme) => ({
  biggerFont: {
    fontSize: 16,
    fontWeight: 600,
  },
  fontWeight: {
    fontWeight: 500,
    letterSpacing: "0.02em",
  },
  wrapper: {
    boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
    borderRadius: 20,
    padding: "12px 20px",
    width: "100%",
    margin: "0 auto",
  },
  letterSpacing: {
    letterSpacing: "0.02em",
  },
  greenText: {
    color: theme.palette.success.main,
  },
  payingForText: {
    fontWeight: 600,
  },
  textCenter: {
    "& input": {
      textAlign: "center",
      paddingLeft: "0px !important",
    },
  },
});

export default withStyles(styles)(SplitBillByAmount);
