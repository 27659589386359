import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "./constant";

import TextField from "../text-field";
import langFn from "@util/lang";
import StripeInput from "./stripeElements";

import "./style.css";

const cardIconStyle = {
  height: 10,
  paddingRight: 9,
};

function CardForm({ updatePaymentDetails, classes, onNameChange }) {
  const [name, setName] = React.useState("Sales demo user");
  const [cardNumber, setCardNumber] = React.useState("4242424242424242");
  const [expiry, setExpiry] = React.useState("11/27");
  const [cvv, setCVV] = React.useState("316");

  React.useEffect(() => {
    onNameChange(name);
  }, [name]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <TextField
          label={langFn("Card number")}
          value={cardNumber}
          onChange={({ target: { value } }) => console.log(value)}
          InputProps={{
            inputComponent: CardNumberElement,
            // inputProps: {
            //   component: CardNumberElement,
            // },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <TextField
          label={langFn("Cardholder name")}
          fullWidth
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={langFn("Expiry date")}
          placeholder="MM / YY"
          value={expiry}
          className={classes.inputTextCenter}
          InputProps={{
            inputComponent: CardExpiryElement,
            // inputProps: {
            //   component: CardExpiryElement,
            // },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={langFn("CVV / CVC")}
          fullWidth
          value={cvv}
          InputProps={{
            inputComponent: CardCvcElement,
            // inputProps: {
            //   component: CardCvcElement,
            // },
          }}
        />
      </Grid>
    </Grid>
  );
}

CardForm.propTypes = {};
const styles = (theme) => ({
  inputTextCenter: {
    "& .MuiInput-input": { textAlign: "left" },
  },
});
export default withStyles(styles)(CardForm);
