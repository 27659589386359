import React, { Component } from "react";
import PropTypes from "prop-types";

import BillSummaryComponent from "@components/bill-summary";

class BillSummary extends Component {
  componentDidMount = () => {
    const { getBillDetails, match } = this.props;
    const { tableId } = match.params;

    if (!tableId) {
      return alert("Invalid table id");
    }

    // getBillDetails({ tableId });
  };

  render() {
    const { bill, match, items, isLoading } = this.props;
    const { tableId } = match.params;
    // console.log(this.props);

    if (!bill?.id || isLoading) {
      return null;
    }

    return <BillSummaryComponent bill={bill} tableId={tableId} items={items} />;
  }
}

BillSummary.propTypes = {
  getBillDetails: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  bill: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default BillSummary;
