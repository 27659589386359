import React from "react";

import { Divider } from "@material-ui/core";

function FullWidthDivider(props) {
  return (
    <div style={{ position: "absolute", width: "100vw", left: 0 }}>
      <Divider variant="fullWidth" />
    </div>
  );
}

export default FullWidthDivider;
