import billLoading from "./billLoading";
import billNotFound from "./noBillFound";
import billSummary from "./billSummary";
import confirmPage from "./confirmPage";
import rating from "./rating";
import splitByAmount from "./splitByAmount";
const lang = {
  share_note: "",
  card_number: "Numéro de carte",
  cardholder_name: "Nom du titulaire de carte",
  expiry_date: "Date d’expiration",
  tip_charles: "Pourboire Charles",
  tip: "Pourboire",
  pay: "Payer",
  paid_by_card: "Payé par carte",
  goodbye_thanks: "Au Revoir, Merci",
  enter_email_for_receipt: "Saisir email pour reçu",
  email_address: "Adresse email",
  send_or_download_here: "Envoyer ou télécharger ici ",
  how_do_you_rate_us: "Quelle note vous nous donnez",
  thank_you: "Merci",
  we_would_like_to_know_what_you_think_of_us:
    "Nous aimerions savoir ce que vous pensez de nous",
  submit: "Soumettre",
  version: "Version",
  thank_you_for_your_feedback: "Merci pour vos commentaires",
  your_receipt_has_been_sent: "Votre reçu a été envoyé",
  demo: "Démo",
  split_the_bill: "Partager l'addition",
  custom_tip: "Conseils personnalisés",
  payment_completed: "Paiement terminé",
  thank_you_for_dining_with_us: "Merci d'avoir dîné avec nous, à bientôt!",
  or_download_here: "Ou téléchargez ici",
  how_did_we_get_on: "Nous aimerions savoir ce que vous pensez de nous!",
  tell_us_about_your_experience: "Parlez-nous de votre expérience",
  wed_love_to_know: "Nous serions ravis de savoir",
  submit_feedback: "Nous faire parvenir",
  additional_feedback_optional: "Commentaires supplémentaires (facultatif)",
  food: "Aliments",
  recommendation: "Recommandation",
  hygiene: "Hygiène",
  send: "Envoyer",
  by_amount: "Par montant",
  by_items: "Par articles",
  split_by_equal_share: "Répartir à parts égales",
  split_the_bill_equally_between_your_friendsfamily:
    "Répartissez la facture à parts égales entre vos amis/famille.",
  your_name: "Votre nom",
  i_want_to_split_by: "Je veux diviser par",
  people: "gens",
  pay_for: "payer pour",
  i_am_paying: "Je paye",
  want_to_pay_a_different_amount: "Vous souhaitez payer un montant différent",
  see_how_much_your_friendsfamily_are_paying:
    "Voyez combien vos amis/famille paient",
  outstanding_balance: "Solde impayé",
  pending: "En attente",
  paid: "Payé",
  split_by_items: "Diviser par éléments",
  split_the_bill_by_selecting_the_items_you_want_to_pay_for:
    "Fractionnez la facture en sélectionnant les articles que vous souhaitez payer",
  remaining_total: "Total restant",
  checkout: "Vérifier",
  please_enter_name: "S'il vous plaît entrez votre nom",
  cannot_pay_more_than_total_amount:
    "Impossible de payer plus que le montant total",
  please_enter_valid_email_address: "Veuillez saisir une adresse e-mail valide",
  overview: "Aperçu",
  the_outstanding_balance_has_exceeded_the_total_you_only_need_to_pay:
    "Le solde restant a dépassé le total. Vous n'avez qu'à payer",
  you: "Toi",
  instead_of: "au lieu de ",
  ...billLoading,
  ...billNotFound,
  ...billSummary,
  ...confirmPage,
  ...rating,
  ...splitByAmount,
};

export default lang;
