import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { formatToCurrency } from "@utils/currency";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckIcon from "@components/check-icon";
import ZigZagText from "@components/zig-zag-text";
import Icons from "@components/icons";
import langFn from "@util/lang";

import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  withStyles,
} from "@material-ui/core";

import Typography from "@components/typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "../text-field";

const currentLocation = window.location.href;

function createData(name, quantity, price, enabled, checked) {
  return { name, quantity, price, enabled, checked };
}

let rowsData = [
  createData(" Le burger au fromage", 1, 3.7, true, false),
  createData("Noix de saint jacques", 1, 3.8, true, false),
  createData("Soupe du jour", 1, 8.25, true, false),
  createData("Salade de tomates vertes", 1, 8.25, true, false),
];

if (!currentLocation.includes("fr")) {
  rowsData = [
    createData(" Chicken Butterfly", 1, 3.7, true, false),
    createData("Halloumi Sticks", 1, 3.8, true, false),
    createData("Spicy Mixed Olives", 1, 8.25, true, false),
    createData("Sunset Burger", 1, 8.25, true, false),
  ];
}

const add = (accumulator, a) => accumulator + a.price;

function SplitBillByItem({ classes, updateTotalAmount, ...props }) {
  const [rows, updateRow] = React.useState(rowsData);
  const [total, updateTotal] = React.useState(0);

  const totalBillAmount = rows.reduce(add, 0);

  const updateCheck = (index) => {
    const newData = rows;
    const checked = newData[index].checked;
    newData[index].checked = !checked;

    const total = newData
      .filter((item) => item.checked && item.enabled)
      .reduce(add, 0);

    updateRow([...newData]);
    updateTotal(total);
    updateTotalAmount(total);
  };

  React.useEffect(() => {
    //console.log(rows);
  });

  return (
    <Grid container spacing={3} className={classes.wrapper}>
      <Grid item xs="12">
        <Typography
          variant="h2"
          weight="semiBold"
          gutterBottom
          className={classes.letterSpacing}
        >
          {langFn("Split by items")}
        </Typography>
        <Typography variant="h3" />
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.letterSpacing}
        >
          {langFn("Split the bill by selecting the items you want to pay for")}.
        </Typography>
      </Grid>
      <Grid item xs="12">
        <TextField
          placeholder={langFn("Your name")}
          fullWidth
          size="small"
          className={classes.textCenter}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          align="center"
          className={classes.letterSpacing}
        >
          {langFn("Remaining Total")}{" "}
          <ZigZagText
            text={
              <strong>
                {props.currencySymbol}{" "}
                {formatToCurrency(totalBillAmount - total)}{" "}
              </strong>
            }
            variant="dark"
            className={classes.biggerFont}
          />
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: "45%", paddingLeft: 0 }}
                    >
                      <Typography variant="h3">
                        <strong> {row.quantity}</strong> {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: "20%", padding: "16px 0" }}
                    >
                      <Typography variant="h3" align="center">
                        <strong>
                          {" "}
                          {props.currencySymbol} {formatToCurrency(row.price)}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="right"
                      style={{ width: "5%", paddingRight: 0, paddingLeft: 0 }}
                    >
                      <Checkbox
                        checked={row.checked}
                        onChange={() => updateCheck(index)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        checkedIcon={<CheckIcon />}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            className={classes.uncheckedIcons}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} justifyContent="center" container>
        <Grid item xs={6} container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              weight="medium"
              align="center"
              className={classes.payingForText}
            >
              {langFn("I am paying")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              weight="semiBold"
              align="center"
              className={[classes.payingForText, classes.greenText].join(" ")}
            >
              {props.currencySymbol} {formatToCurrency(total)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SplitBillByItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  biggerFont: {
    fontSize: 16,
    fontWeight: 600,
  },
  fontWeight: {
    fontWeight: 500,
  },
  wrapper: {
    boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
    borderRadius: 20,
    padding: "12px 19px",
    width: "100%",
    margin: "0 auto",
  },
  letterSpacing: {
    letterSpacing: "0.02em",
  },
  greenText: {
    color: theme.palette.success.main,
  },
  payingForText: {
    fontWeight: 600,
  },
  uncheckedIcons: {
    boxShadow: "0px 0px 5px 2px rgba(139, 139, 139, 0.3)",
    color: "#fff",
    borderRadius: "5px !important",
  },
  textCenter: {
    "& input": {
      textAlign: "center",
      paddingLeft: "0px !important",
    },
  },
});

export default withStyles(styles)(SplitBillByItem);
