import React from "react";
import PropTypes from "prop-types";
import PaymentSuccess from "@containers/payment-success-2";
import ZigZagText from "@components/zig-zag-text";
// Material UI Imports
import { Grid, Box } from "@material-ui/core";
import PaymentSuccessOverview from "@components/payment-success-overview";
import SendPaymentReceipt from "@containers/send-payment-receipt";
import Rating from "@containers/rating";
import AdditionalFeedback from "@containers/additional-feedback";
import FullWidthDivider from "@components/full-width-divider";
import AdditionalReview from "@components/additional-review";
import { useSelector } from "react-redux";

function PaymentSuccessPage(props) {
  const { feedbackSubmitted = false } = useSelector((state) => state.ui);
  return (
    <Box pt={5} mb={6} className="payment-success-page">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaymentSuccessOverview />
        </Grid>
        <Grid item xs={12}>
          <FullWidthDivider />
        </Grid>
        <Grid item xs={12}>
          <SendPaymentReceipt />
        </Grid>
        <Grid item xs={12}>
          <Rating />
        </Grid>
        <Grid item xs={12}>
          <AdditionalFeedback />
        </Grid>
        <AdditionalReview />
      </Grid>
    </Box>
  );
}

PaymentSuccessPage.propTypes = {};

export default PaymentSuccessPage;
