import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@material-ui/core";

import { useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";

function FooterLogo() {
  const { version = "1.0.0" } = useSelector((state) => state.ui);

  const currentLocation = window.location.href;
  let banner = "/demo-assets/pound-bottom-banner.svg";
  if (currentLocation.includes("4")) {
    banner = "/demo-assets/dollor-bottom-banner.svg";
  }
  if (currentLocation.includes("fr")) {
    banner = "/demo-assets/banner-french.png";
  }
  if (currentLocation.includes("6")) {
    banner = "/demo-assets/banner-sar.svg";
  }

  return (
    <Box pb={2}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={banner}
              style={{ marginBottom: -24, width: "-webkit-fill-available" }}
              alt="fetch-my-bill"
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Version {version}
          </Typography>
        </Grid> */}
      </Grid>
    </Box>
  );
}

FooterLogo.propTypes = {};

export default FooterLogo;

{
  /* <Grid xs={12} item container justifyContent="center">
             
            </Grid>
            <Grid xs={12} item>
            
            </Grid> */
}
