import React from "react";
import { SEND_RECEIPT_EMAIL } from "../query";
import GraphqlClient from "@root/services/GraphqlService";

export const SEND_RECEIPT_VARIABLE = (params) => ({
  variables: {
    input: {
      ...params,
    },
  },
});

export default function sendReceiptEmail(params) {
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve(dummyData?.data?.getBill);
  //   }, 300);
  // });

  return GraphqlClient.get(SEND_RECEIPT_EMAIL, SEND_RECEIPT_VARIABLE(params))
    .then(({ data, errors }) => {
      if (errors) {
        throw errors[0];
      }
      const dataResponse = data?.sendBillReceipt ?? false;
      return dataResponse;
    })
    .catch((error) => {
      throw error;
    });
}
