import {
  Grid,
  Button as MuiButton,
  Typography,
  withStyles,
  InputAdornment,
  ClickAwayListener,
} from "@material-ui/core";

export const buttonPropsOutlined = {
  variant: "outlined",
  color: "primary",
  size: "small",
};

export const buttonPropsContained = {
  variant: "contained",
  color: "primary",
  size: "small",
};

export const Button = withStyles((theme) => ({
  root: {
    boxShadow: "0px 0px 5px 2px rgba(139, 139, 139, 0.1)",
    minWidth: "unset",
    borderColor: "transparent !important",
    padding: "5px 12px",
    fontWeight: 500,
    fontSize: 14,
    "& .MuiButton-label": {
      color: "#000",
    },
  },
  contained: {
    "& .MuiButton-label": {
      color: "#fff",
    },
  },
}))(MuiButton);

export const buttonProps = (enabled, name) =>
  enabled === name ? buttonPropsContained : buttonPropsOutlined;
