import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ZigZagText from "@components/zig-zag-text";
import { Grid, Box, Typography } from "@material-ui/core";

function AdditionalReview(props) {
  const { bill = {} } = useSelector((state) => state.bill);
  const { averageRating, feedbackSubmitted, additionalFeedbackSubmitted } =
    useSelector((state) => state.feedback);

  const { outlet = {} } = bill;
  const { feedbackSetting = {} } = outlet;

  const {
    feedbackSharingPlatforms,
    feedbackSharingEnabled,
    feedbackSharingRatingThreshold: ratingThreshold,
  } = feedbackSetting;

  const successMessageText = (
    <>
      <div>
        <Typography align="center">
          Thank you {!additionalFeedbackSubmitted ? "for your rating" : null}!
        </Typography>
      </div>
      {feedbackSubmitted && (
        <div>
          <Typography align="center">Want to share your feedback?</Typography>
        </div>
      )}
    </>
  );

  const showAdditionalFeedback = ratingThreshold <= averageRating;

  if (!feedbackSubmitted && !additionalFeedbackSubmitted) {
    return null;
  }
  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <ZigZagText text={successMessageText} variant="dark" />
        </Box>
      </Grid>
      {feedbackSharingEnabled && showAdditionalFeedback && feedbackSubmitted && (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={3}>
            {feedbackSharingPlatforms.map((item) => (
              <Grid item key={item.url}>
                <Typography align="center">
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <img
                      src={item.logoUrl}
                      height={50}
                      width={50}
                      alt={`${item.displayName}-review`}
                    />
                  </a>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}

AdditionalReview.propTypes = {};

export default AdditionalReview;
