import { createSlice } from "@reduxjs/toolkit";

const order = createSlice({
  name: "order",
  initialState: {
    itemTotalAmount: 0,
    totalAmount: 0,
    tipAmount: 0,
    tableNumber: 0,
    iAmPaying: 0,
    status: "idle",
    error: null,
  },
  reducers: {
    updateTotalAmount: (state, { payload }) => ({
      ...state,
      totalAmount: parseFloat(payload.toFixed(2)),
    }),
    updateTipAmount: (state, { payload }) => ({ ...state, tipAmount: payload }),
    updateIamPaying: (state, { payload }) => ({
      ...state,
      iAmPaying: Boolean(payload) ? payload : 0,
    }),
  },
});

export const action = order.actions;

export default order;
