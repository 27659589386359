import { createSlice } from "@reduxjs/toolkit";

const ui = createSlice({
  name: "ui",
  initialState: {
    version: "2.2.1",
    logo: "./logo.png",
    brandingLogo: "./assets/images/logo.png",
    showCheckoutForm: true,
    showSplitBill: false,
    tipsButtonEnabled: -1,
    customTipAmount: "",
    paymentMode: true,
    feedbackSubmitted: false,
  },
  reducers: {
    updateTotalAmount: (state, { payload }) => ({
      ...state,
      totalAmount: payload,
    }),
    updateTipAmount: (state, { payload }) => ({ ...state, tipAmount: payload }),
    toggleCheckoutForm: (state, { payload }) => ({
      ...state,
      showCheckoutForm: payload,
    }),
    toggleSplitBill: (state, { payload }) => ({
      ...state,
      showSplitBill: payload,
    }),
    updateTipsButtonEnabled: (state, { payload }) => {
      return {
        ...state,
        tipsButtonEnabled: payload,
      };
    },
    togglePaymentMode: (state, { payload }) => {
      return {
        ...state,
        paymentMode: payload,
      };
    },
    updateCustomTipAmount: (state, { payload }) => ({
      ...state,
      customTipAmount: payload,
    }),
    updateFeedbackSubmitted: (state, { payload }) => ({
      ...state,
      feedbackSubmitted: payload,
    }),
  },
});

export const action = ui.actions;

export default ui;
