import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import * as orderSelector from "@store/order/selector";
import * as billSelector from "@store/bill/selector";

import PaymentSuccess from "./PaymentSuccess";
import Style from "./style";

const mapStateToProps = (state) => ({
  totalAmount: orderSelector.getTotalAmount(state),
  tipAmount: orderSelector.getTipAmount(state),
  bill: billSelector.getBill(state),
});

const mapDispatchToProps = {};

export default withStyles(Style)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentSuccess)
);
