import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider, withStyles, Box } from "@material-ui/core";
import ZigZagText from "@components/zig-zag-text";
import Typography from "@components/typography";

import { useSelector } from "react-redux";
import { formatToCurrency } from "@utils/currency";
import langFn from "@util/lang";

function SplitBillOverview({ classes, currencySymbol, ...props }) {
  const iAmPaying = useSelector((state) => state.order.iAmPaying);
  const payments = useSelector(({ bill: { bill } }) => bill);

  const amounts = {
    totalPayable: payments.totalAmount,
    remainingToPay: payments.outstandingBalance,
    james: payments.payee1,
    tina: payments.payee2,
    leo: payments.payee3,
    emma: payments.payee4,
  };

  return (
    <Grid container className={classes.wrapper} spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          weight="semiBold"
          className={classes.letterSpacing}
          gutterBottom
        >
          {langFn("Overview")}
        </Typography>
        <Typography variant="h5" className={classes.letterSpacing}>
          {langFn("See how much your friends/family are paying")}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item>
          <Typography variant="h2" weight="semiBold">
            {langFn("Total")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" weight="semiBold">
            {`${currencySymbol} ${amounts.totalPayable.toFixed(2)}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* Paid total */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        spacing={1}
        style={{ paddingRight: 0 }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.greenText}
            gutterBottom
          >
            <ZigZagText
              text={<strong>{langFn("Paid")}</strong>}
              variant="light"
            />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            variant="h3"
            weight="semiBold"
            className={classes.letterSpacing}
          >
            <strong>James</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.greenText}
          >
            <strong>{`${currencySymbol} ${amounts.james}`}</strong>
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            variant="h3"
            weight="semiBold"
            className={classes.letterSpacing}
          >
            <strong>Tina</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.greenText}
          >
            <strong>{`${currencySymbol} ${amounts.tina}`}</strong>
          </Typography>
        </Grid>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      {/* Pending total */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        spacing={1}
        style={{ paddingRight: 0 }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.disabledText}
            gutterBottom
          >
            <ZigZagText
              text={<strong>{langFn("Pending")}</strong>}
              variant="disabled"
            />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            variant="h3"
            weight="semiBold"
            className={classes.letterSpacing}
          >
            <strong>Leo</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.disabledText}
          >
            <strong>{`${currencySymbol} ${amounts.leo}`}</strong>
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            variant="h3"
            weight="semiBold"
            className={classes.letterSpacing}
          >
            <strong>Emma</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.disabledText}
          >
            <strong>{`${currencySymbol} ${amounts.emma}`}</strong>
          </Typography>
        </Grid>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h3"
            weight="semiBold"
            align="right"
            className={classes.letterSpacing}
          >
            <strong>{langFn("You")}</strong>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h3" weight="semiBold" align="right">
            <strong>
              {" "}
              {currencySymbol} {formatToCurrency(iAmPaying)}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {/* Remaning total */}

      <Grid item xs={12} justifyContent="center" container>
        <Grid item xs={8} container spacing={1}>
          <Grid item xs={12}>
            {" "}
            <Typography
              variant="h3"
              weight="medium"
              align="center"
              style={{ fontWeight: 500 }}
              className={[classes.letterSpacing, classes.disabledText].join(
                " "
              )}
            >
              {langFn("Outstanding Balance")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {" "}
            <Typography
              variant="h3"
              weight="medium"
              align="center"
              color="success"
              gutterBottom
            >
              <ZigZagText
                text={
                  <strong>
                    &nbsp;&nbsp;{currencySymbol}{" "}
                    {formatToCurrency(amounts.remainingToPay - iAmPaying)}
                    &nbsp;&nbsp;
                  </strong>
                }
                variant="dark"
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item />
        {amounts.remainingToPay - iAmPaying < 0 ? (
          <Grid item xs={12}>
            <Box pt={2}>
              <Typography variant="h4" color="error" align="center">
                {langFn(
                  "The outstanding balance has exceeded the total. You only need to pay"
                )}{" "}
                <Box component="span" color="text.primary">
                  <strong>
                    {currencySymbol} {formatToCurrency(amounts.remainingToPay)}
                  </strong>
                </Box>{" "}
                {langFn("instead of")} {currencySymbol}{" "}
                {formatToCurrency(iAmPaying)}.
              </Typography>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}

SplitBillOverview.propTypes = {};

const styles = (theme) => ({
  wrapper: {
    boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
    borderRadius: 20,
    padding: "12px 20px",

    width: "100%",
    margin: "0 auto",
  },
  letterSpacing: {
    letterSpacing: "0.02em",
  },
  greenText: {
    color: theme.palette.success.main,
  },
  payingForText: {
    fontWeight: 600,
  },
  disabledText: {
    color: "#A6A6A6",
  },
});

export default withStyles(styles)(SplitBillOverview);
