import React, { Component } from "react";
import PropTypes from "prop-types";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import StripeService from "@root/services/StripeService";
import CardFrom from "@components/card-form";
import PaymentMethodButton from "@components/payment-method-button";
import { formatToCurrency } from "@utils/currency";
import { detectDevice } from "@utils/device";
import { Grid } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Tips from "@containers/tips";
import StripeCardPayment from "../stripe-card-payment";
import PaymentLoadingSpinner from "../payment-loading-spinner";
import PaymentMessage from "../payment-message";

import { ButtonShadowed } from "./constant";
import StripePaymentButton from "@containers/stripe-payment-button";
import langFn from "@util/lang";

const PUB_KEY = `${process.env.STRIPE_PUB_KEY}`;
const StripeInstance = loadStripe(PUB_KEY);

class StripePayment extends Component {
  state = {
    device: "google",
    cardDetails: {},
    error: false,
    clientSecret: null,
    errorMessage: null,
    isLoading: false,
    paymentId: null,
  };

  componentDidMount = () => {
    const device = detectDevice();
    if (device === "iOS") {
      this.setState({ device: "apple" });
    }
    this.createPaymentIntent();
  };

  componentDidUpdate = (prevProps) => {
    const { totalAmount, tipAmount } = this.props?.orderDetail;
    const { totalAmount: prevTotalAmount, tipAmount: prevTipAmount } =
      prevProps?.orderDetail;

    if (totalAmount !== prevTotalAmount || tipAmount !== prevTipAmount) {
      this.updatePaymentIntent();
    }
  };

  getIntentPayload = () => {
    const { orderDetail, bill } = this.props;
    const { outlet, billNumber: clientReferenceNumber } = bill;
    const { id: outletId } = outlet;
    const { totalAmount, tipAmount } = orderDetail;

    if (totalAmount <= 0) {
      return false;
    }

    const paymentIntentPayload = {
      amount: totalAmount + tipAmount,
      tipAmount: tipAmount,
      currencyCode: "GBP",
      description: "Payment",
      transactionType: "SALE",
      paymentGateway: "stripe",
      outletId,
      clientReferenceNumber,
      serviceChargeAmount: 0,
       statementDescriptor: 'Demo app',
      serviceCharge: {
        serviceChargeFeePercentage: 100,
        serviceChargeFeeAmount: 1.25,
        isServiceChargeEnabled: true,
        isServiceChargeOptable: false,
      },
    };

    return paymentIntentPayload;
  };

  createPaymentIntent = async () => {
    const paymentIntentPayload = this.getIntentPayload();

    if (!paymentIntentPayload) {
      return false;
    }

    await StripeService.createPaymentIntent(paymentIntentPayload).then(
      (res) => {
        this.setState({
          clientSecret: res?.clientSecret,
          paymentId: res.paymentId,
        });
      }
    );
  };

  updatePaymentIntent = async () => {
    const paymentIntentPayload = this.getIntentPayload();
    const { paymentId } = this.state;

    if (!paymentIntentPayload) {
      return false;
    }

    await StripeService.updatePaymentIntent({
      paymentId,
      ...paymentIntentPayload,
    }).then((res) => {});
  };

  updatePaymentDetails = (cardDetails) => {
    this.setState({ cardDetails });
  };

  render() {
    const { device, error, message, isLoading, clientSecret } = this.state;
    const {
      orderDetail,
      checkoutFormStatus,
      toggleCheckoutForm,
      showSplitBill,
      activePaymentMode,
      togglePaymentMode,
      bill,
    } = this.props;
    const { totalAmount, tipAmount } = orderDetail;

    const {
      outlet: { currencySymbol },
    } = bill;

    return (
      <>
        {!checkoutFormStatus ? (
          <Grid xs={12} item container justifyContent="center">
            <Grid xs={12} item>
              <ButtonShadowed
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                style={{ fontSize: 20, fontWeight: "bold", borderRadius: 10 }}
                onClick={() => toggleCheckoutForm(!checkoutFormStatus)}
                elevation={5}
              >
                <LockRoundedIcon /> &nbsp; &nbsp; {langFn("Checkout")} -{" "}
                {currencySymbol}{" "}
                {formatToCurrency(parseFloat(totalAmount) + tipAmount)}
              </ButtonShadowed>
            </Grid>
          </Grid>
        ) : (
          clientSecret && (
            <Elements options={{ clientSecret }} stripe={StripeInstance}>
              <Grid container spacing={6}>
                <Grid xs={12} item>
                  <PaymentMethodButton
                    device={device}
                    clientSecret={clientSecret}
                    togglePaymentMode={togglePaymentMode}
                    {...this.props}
                  />
                </Grid>
                {activePaymentMode && (
                  <Grid xs={12} item>
                    <StripeCardPayment
                      {...this.props}
                      clientSecret={clientSecret}
                    />
                  </Grid>
                )}
                {!activePaymentMode && (
                  <React.Fragment>
                    <Grid xs={12} item>
                      <Tips />
                    </Grid>

                    <Grid xs={12} item>
                      <StripePaymentButton
                        {...this.props}
                        clientSecret={clientSecret}
                      >
                        <PaymentMessage />
                        <ButtonShadowed
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            borderRadius: 10,
                          }}
                          elevation={5}
                        >
                          <LockRoundedIcon /> &nbsp; &nbsp; {langFn("Pay")} -
                          {currencySymbol}{" "}
                          {formatToCurrency(
                            parseFloat(totalAmount) + tipAmount
                          )}
                        </ButtonShadowed>
                      </StripePaymentButton>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Elements>
          )
        )}
        <PaymentLoadingSpinner />
      </>
    );
  }
}

StripePayment.propTypes = {
  orderDetail: PropTypes.object.isRequired,
};

export default StripePayment;
