import React, { Component } from "react";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import FeedbackService from "@root/graphql/feedback/hooks/addFeedback";
import PaymentSuccessOverview from "@components/payment-success-overview";
import SendPaymentReceipt from "@components/send-payment-receipt";
import PaymentRating from "@components/payment-rating";
import FeedbackMessage from "@components/feedback-message";
import FeedbackInput from "@components/feedback-input";

import { getPayload } from "./constant";
import Typography from "@components/typography";

import Modal from "@components/modal";
import { Grid, Button } from "@material-ui/core";
import SendEmailReceipt from "@root/graphql/bill/hooks/sendReceiptEmail";
import DownloadEmailReceipt from "@root/graphql/bill/hooks/downloadReceiptEmail";

const downloadFile = (file, name) => saveAs(file, name);

class PaymentSuccess extends Component {
  state = {
    dialogFeedback: false,
    dialogFeedback2: false,
    dialogReceipt: false,
    dialogReceiptError: false,
    feedbackError: false,
    values: {},
  };

  sendReceiptEmail = () => {
    const {
      dialogReceiptError,
      values: { email },
    } = this.state;

    const {
      bill: { billNumber },
    } = this.props;

    if (!String(email).includes("@")) {
      return this.setState({ dialogReceiptError: true });
    }

    SendEmailReceipt({
      email,
      billNumber,
    }).then((res) =>
      this.setState({ dialogReceipt: true, dialogReceiptError: false })
    );
  };

  onDownloadClick = () => {
    const {
      bill: { billNumber },
    } = this.props;
    DownloadEmailReceipt({
      billNumber,
    }).then((res) => {
      if (res?.cardReceiptFileUrl) {
        downloadFile(res?.cardReceiptFileUrl, "payment-receipt.pdf");
      }

      if (res?.billReceiptFileUrl) {
        setTimeout(() => {
          downloadFile(res?.billReceiptFileUrl, "bill-receipt.pdf");
        }, 1000);
      }
    });
  };

  onChange = ({ target: { value, name } }) => {
    this.setState(({ values }) => ({ values: { ...values, [name]: value } }));
  };

  onFeedbackSubmit = () => {
    const { values } = this.state;

    const { feedback, food, service, hygiene } = values;

    if (!feedback || !food || !service || !hygiene) {
      return this.setState({ feedbackError: true });
    }
    this.setState({ feedbackError: false });

    const { billNumber: id } = this.props.bill;
    const payload = getPayload(id, values);

    FeedbackService(payload)
      .then((res) => {
        this.setState({
          dialogFeedback: true,
          dialogFeedback2: true,
        });
      })
      .catch((res) => console.log(res));
  };

  render() {
    const {
      dialogFeedback,
      dialogReceipt,
      dialogFeedback2,
      dialogReceiptError,
      feedbackError,
    } = this.state;

    const { classes } = this.props;
    const { onChange, sendReceiptEmail, onDownloadClick } = this;

    return (
      <React.Fragment>
        <Grid container spacing={5} alignItems="baseline">
          <PaymentSuccessOverview {...this.props} />
          <SendPaymentReceipt
            {...this.props}
            onChange={onChange}
            sendReceiptEmail={sendReceiptEmail}
            onDownloadClick={onDownloadClick}
          />
          <PaymentRating onChange={onChange} />

          <FeedbackMessage
            {...this.props}
            dialogFeedback={dialogFeedback}
            onChange={onChange}
          />

          {!dialogFeedback && (
            <FeedbackInput
              {...this.props}
              onChange={onChange}
              onFeedbackSubmit={this.onFeedbackSubmit}
              feedbackError={feedbackError}
            />
          )}
          <Grid xs={12} item container justifyContent="center">
            <Grid item>
              {" "}
              <img src="/assets/images/logo2.svg" style={{ height: 24 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Version 2.2.1
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} item container justifyContent="center" />
        </Grid>
        {dialogReceipt && (
          <Modal
            message="Your receipt has been sent!"
            onClose={() => this.setState({ dialogReceipt: false })}
          />
        )}
        {dialogFeedback2 && (
          <Modal
            message="Thank You for Your Feedback!"
            onClose={() => this.setState({ dialogFeedback2: false })}
          />
        )}
      </React.Fragment>
    );
  }
}

PaymentSuccess.propTypes = {};

export default PaymentSuccess;
