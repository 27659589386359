import uae from "./aed";
import sar from "./sar";
const feedbackSetting = {
  food: true,
  service: true,
  hygiene: false,
  recommendation: true,
  feedbackSharingEnabled: true,
  feedbackSharingRatingThreshold: 8,
  feedbackSharingPlatforms: [
    {
      displayName: "Google",
      url: "https://goo.gl/maps/bcQ4KeyekSmMw72M9",
      logoUrl:
        "https://fmb-assets-dev.s3.eu-west-1.amazonaws.com/fmb-dev-multimedia//a388c7b0-d128-11ec-9025-399c36a2eb05.g_logo.png",
    },
    {
      displayName: "Tripadvisor",
      url: "https://www.tripadvisor.co.uk/",
      logoUrl:
        "https://fmb-assets-dev.s3.eu-west-1.amazonaws.com/fmb-dev-multimedia//6762fbc0-d128-11ec-9025-399c36a2eb05.t_adviser.png",
    },
  ],
};

const outlet = {
  id: "97",
  name: null,
  imageUrl: null,
  logoUrl:
    "https://fmb-demo-four.s3.eu-west-1.amazonaws.com/assets/images/client-logo.png",
  outletLogoUrl: null,
  appSettings: {
    label: "Tips",
    tipsEnabled: true,
    tipsType: "ByPercentage",
    tipsDefaultValues: [12.5, 15, 17.5],
  },
  feedbackSetting: {
    food: true,
    service: true,
    hygiene: false,
    recommendation: true,
  },
  feedbackSetting,
  currencySymbol: "$",
  banner: "/demo-assets/pound-bottom-banner.svg",
};

const lineItems = [
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Spicy Mixed Olives",
    costPrice: 3.7,
    unitPrice: 3.7,
    quantity: 1,
    comboItems: [],
  },
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Halloumi Sticks",
    costPrice: 3.8,
    unitPrice: 3.8,
    quantity: 1,
    comboItems: [],
  },
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Sunset Burger",
    costPrice: 8.25,
    unitPrice: 8.25,
    quantity: 1,
    comboItems: [],
  },
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Chicken Butterfly",
    costPrice: 8.25,
    unitPrice: 8.25,
    quantity: 1,
    comboItems: [],
  },
];

const lineItemsFrench = [
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Salade de tomates vertes",
    costPrice: 3.7,
    unitPrice: 3.7,
    quantity: 1,
    comboItems: [],
  },
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Soupe du jour",
    costPrice: 3.8,
    unitPrice: 3.8,
    quantity: 1,
    comboItems: [],
  },
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Le burger au fromage",
    costPrice: 8.25,
    unitPrice: 8.25,
    quantity: 1,
    comboItems: [],
  },
  {
    id: "5739",
    note: null,
    discount: null,
    name: "Noix de saint jacques",
    costPrice: 8.25,
    unitPrice: 8.25,
    quantity: 1,
    comboItems: [],
  },
];

const generalOrderConfig = {
  id: "210",
  billNumber: "20220309UK-97-00001",
  tableName: "1",
  payAmount: null,
  serviceCharge: null,
  totalAmount: 24.0,
  subTotal: 24.0,
  discount: 0,
};

const splitBillOverviewDetails = {
  totalPaid: 16,
  outstandingBalance: 8,
  payee1: "5.00",
  payee2: "5.00",
  payee3: "1.00",
  payee4: "5.00",
};

const fourth = {
  ...generalOrderConfig,
  ...splitBillOverviewDetails,
  outlet: {
    ...outlet,
    currencySymbol: "$",
    banner: "/demo-assets/dollor-bottom-banner.svg",
  },
  lineItems,
  tableName: 48,
};

const first = {
  ...generalOrderConfig,
  ...splitBillOverviewDetails,
  outlet: { ...outlet, currencySymbol: "£" },
  lineItems,
};

const second = {
  ...generalOrderConfig,
  ...splitBillOverviewDetails,
  outlet: { ...outlet, currencySymbol: "€" },
  lineItems,
};

const third = {
  ...generalOrderConfig,
  ...splitBillOverviewDetails,
  outlet: { ...outlet, currencySymbol: "CHF" },
  lineItems,
};

const en = {
  ...generalOrderConfig,
  ...splitBillOverviewDetails,
  outlet: { ...outlet, currencySymbol: "£" },
  lineItems,
};

const fr = {
  ...generalOrderConfig,
  ...splitBillOverviewDetails,
  outlet: {
    ...outlet,
    currencySymbol: "€",
    banner: "/demo-assets/banner-french.png",
    appSettings: { ...outlet.appSettings, label: "Pourboire Charles" },
  },

  lineItems: lineItemsFrench,
};

// const uae = {
//   ...generalOrderConfig,
//   outlet: {
//     ...outlet,
//     currencySymbol: "AED",
//     banner: "/demo-assets/banner-aed.svg",
//   },
//   lineItems,
// };

const getBill = {
  1: first,
  2: second,
  3: third,
  4: fourth,
  5: uae,
  6: sar,
  en,
  fr,
  uae,
  sar,
};

export default getBill;
