import React, { useEffect, useState } from "react";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import CardFrom from "@components/card-form";
import Tips from "@containers/tips";
import { formatToCurrency } from "@utils/currency";
import { Grid } from "@material-ui/core";
import { ButtonShadowed } from "../stripe-payment/constant";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PaymentMessage from "../payment-message";
import { action as paymentAction } from "@store/payment/reducer";
import {
  PaymentElement,
  useStripe,
  useElements,
  CardNumberElement,
  a,
} from "@stripe/react-stripe-js";

import langFn from "@util/lang";

function StripeCardPayment({ showSplitBill, clientSecret }) {
  const [name, setName] = useState("");
  const updatePaymentDetails = () => {};
  const history = useHistory();
  const { totalAmount = 0, tipAmount = 0 } = useSelector(
    (state) => state.order
  );
  const { billNumber = "", outlet = {} } = useSelector(
    (state) => state.bill?.bill
  );

  const { tableId } = useParams();

  const outletId = outlet?.id;

  const { currencySymbol } = outlet;

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  // const [message, setMessage] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  const setMessage = (message) => dispatch(paymentAction.addMessage(message));
  const setIsLoading = (state) => dispatch(paymentAction.setIsLoading(state));

  const confirm = ({ paymentIntent }) => {
    if (!stripe) {
      return;
    }

    const { client_secret: clientSecret } = paymentIntent;

    if (!clientSecret) {
      return;
    }

    return stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            history.push(
              `/payment-success/${tableId}/${outletId}/${billNumber}`
            );
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!name) {
      return setMessage("Please fill name");
    }

    setIsLoading(true);

    try {
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
          },
        },
      });

      if (response?.error) {
        throw response?.error;
      }

      await confirm(response);
    } catch (error) {
      return setTimeout(() => {
        setIsLoading(false);
        history.push(`/payment-success/${tableId}/${outletId}/${billNumber}`);
      }, 3000);

      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage(error.message);
      }
    }
  };

  return (
    <>
      <Grid xs={12} style={{ marginBottom: 43 }}>
        <CardFrom
          updatePaymentDetails={updatePaymentDetails}
          onNameChange={setName}
        />
      </Grid>

      <Grid xs={12} item style={{ marginBottom: 43 }}>
        <Tips />
      </Grid>

      <PaymentMessage />

      <Grid xs={12} item container justifyContent="center">
        <Grid xs={12} item>
          <ButtonShadowed
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            style={{ fontSize: 20, fontWeight: "bold", borderRadius: 10 }}
            onClick={handleSubmit}
            elevation={5}
          >
            <LockRoundedIcon /> &nbsp; &nbsp; {langFn("Pay")} - {currencySymbol}{" "}
            {formatToCurrency(parseFloat(totalAmount) + tipAmount)}
          </ButtonShadowed>
        </Grid>
      </Grid>
    </>
  );
}

StripeCardPayment.propTypes = {};

export default StripeCardPayment;
