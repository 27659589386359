import APIService from "./APIService";

export default class StripeService extends APIService {
  static createPaymentIntent(params) {
    return this.post("/paymgr/api/v1/payment/intent", {
      body: JSON.stringify({ ...params }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }

  static updatePaymentIntent(params) {
    return this.put("/paymgr/api/v1/payment/intent", {
      body: JSON.stringify({ ...params }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
