import { createSlice } from "@reduxjs/toolkit";
import orderBy from "lodash.orderby";

const sortItems = (item) => {
  if (!item) {
    return [];
  }
  const sortedComboItem = item.map((item) => ({
    ...item,
    comboItems: orderBy(item?.comboItems, ["name"], ["asc"]),
  }));

  return orderBy(sortedComboItem, ["name"], ["asc"]);
};

const items = createSlice({
  name: "bill",
  initialState: {
    bill: {},
    items: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateBill: (state, { payload }) => {
      return {
        ...state,
        items: sortItems(payload?.lineItems),
        bill: payload,
      };
    },
    isLoading: (state, { payload }) => {
      return { ...state, isLoading: payload };
    },
    error: (state, { payload }) => ({ ...state, error: payload, bill: {} }),
  },
});

export const action = items.actions;

export default items;
