import SocketClient from "@root/services/Socket";
import { action as splitBillAction } from "./reducer";

const Socket = new SocketClient();
// Socket.connect()
//   .then((res) => console.log("connect to server", res))
//   .catch((error) => console.log("connect to server error", error));

export const updateName = (params) => (dispatch) => {
  // dispatch(splitBillAction.isLoading(true));
  return Socket.emit("setName", params)
    .then((res) => {
      //     dispatch(splitBillAction.updateBill(res));
    })
    .catch((error) => dispatch(splitBillAction.error(error)))
    .finally(() => dispatch(splitBillAction.isLoading(false)));
};

export const subscribeToRoom = (params) => {
  console.log("init subscribeToRoom", Socket.getId());
  return Socket.emit("setRoom", {
    billNumber: "arpit",
    totalAmount: 24,
    memberId: Socket.getId(),
  })
    .then((res) => console.log("connect to room", res))
    .catch((error) => console.log("connect to room error", error));
};

export const updateAmount = (params) => (dispatch) => {
  // dispatch(splitBillAction.isLoading(true));
  // return Socket.emit("setAmount", params)
  //   .then((res) => {
  //     dispatch(splitBillAction.updateBill(res));
  //   })
  //   .catch((error) => dispatch(splitBillAction.error(error)))
  //   .finally(() => dispatch(splitBillAction.isLoading(false)));
};

export const disconnect = () => {
  console.log("Init disconnnected");
  Socket.disconnect()
    .then((res) => console.log("dis connected ", res))
    .catch((error) => console.log("dis connected error", error));
};

Socket.on("roomInfoChanged", (roomInfo) => {
  splitBillAction.updateInfo(roomInfo);
});

export default {
  updateName,
  updateAmount,
  disconnect,
  subscribeToRoom,
};

// Dummy data
