import React from "react";
import PropTypes from "prop-types";
import { withStyles, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ButtonShadowed = withStyles((theme) => ({
  contained: {
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);",
  },
}))(Button);

function PrimaryButton({ isLoading, children, onClick }) {
  return (
    <ButtonShadowed
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      style={{
        fontSize: 20,
        fontWeight: "bold",
        borderRadius: 10,
      }}
      elevation={5}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress color="secondary" /> : children}
    </ButtonShadowed>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

PrimaryButton.defaultProps = {
  isLoading: false,
  onClick: () => {},
};

export default PrimaryButton;
