import React from "react";
import PropTypes from "prop-types";

import {
  alpha,
  ThemeProvider,
  withStyles,
  makeStyles,
  createTheme,
} from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { green } from "@material-ui/core/colors";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontFamily: theme.fontFamily,
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "0.02em",
      color: "#3A3C3F",
      minWidth: 230,
    },

    // "& .MuiInputLabel-formControl": {
    //   top: -7,
    // },
    "& .MuiInput-root": {
      backgroundColor: "#FFF",
      borderRadius: 10,
      border: "1px solid #E9E9E9",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
      marginTop: 24,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .Mui-focused": {
      borderBottom: 0,
    },
    "& .MuiInput-underline": {
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: 14,
      fontSize: 14,
      fontFamily: theme.fontFamily,
      letterSpacing: "0.05em",
      height: 29,
      color: "#424D59",
    },
  },
}))(TextField);

const BootstrapInput2 = withStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontFamily: theme.fontFamily,
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "0.02em",
      color: "#3A3C3F",
      minWidth: 230,
    },
    "& .MuiInput-root": {
      backgroundColor: "#FFF",
      borderRadius: 10,
      border: "1px solid #E9E9E9",
      boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.04)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .Mui-focused": {
      borderBottom: 0,
    },
    "& .MuiInput-underline": {
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    "& .MuiInputBase-input": {
      paddingLeft: 14,
      paddingBottom: 3,
      fontSize: 14,
      fontFamily: theme.fontFamily,
      height: 23,
      letterSpacing: "0.05em",
      color: "#424D59",
    },
  },
}))(TextField);

const BootstrapTextField = React.forwardRef(({ size, ...props }, ref) => {
  if (size) {
    return (
      <BootstrapInput2
        size={size}
        ref={ref}
        id="bootstrap-input"
        {...props}
        fullwidth="true"
        InputProps={{
          ...props.InputProps,
          startAdornment: props?.InputProps?.startAdornment
            ? props?.InputProps?.startAdornment
            : " ",
        }}
      />
    );
  }
  return (
    <BootstrapInput
      id="bootstrap-input"
      ref={ref}
      {...props}
      fullwidth="true"
      InputProps={{
        ...props.InputProps,
        startAdornment: props?.InputProps?.startAdornment
          ? props?.InputProps?.startAdornment
          : " ",
      }}
    />
  );
});

BootstrapTextField.propTypes = {};

export default BootstrapTextField;
