import React from "react";
import PropTypes from "prop-types";
import SendPaymentReceipt from "@components/send-payment-receipt";
// Material imports
import { Grid, Typography, Divider } from "@material-ui/core";

const NOOP = () => {};
function PaymentSuccess({
  onChange,
  sendReceiptEmail,
  onDownloadClick,
  ...props
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h1">
          Thank you for dining with us,
          <br />
          see you again soon!
        </Typography>
        <Typography variant="h3">Payment Completed - £52.30</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item xs={12}>
        <SendPaymentReceipt
          {...props}
          onChange={onChange}
          sendReceiptEmail={sendReceiptEmail}
          onDownloadClick={onDownloadClick}
        />
      </Grid>
    </Grid>
  );
}

PaymentSuccess.propTypes = {
  onChange: PropTypes.func,
  sendReceiptEmail: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
};

PaymentSuccess.defaultProps = {
  onChange: NOOP,
  sendReceiptEmail: NOOP,
  onDownloadClick: NOOP,
};

export default PaymentSuccess;
