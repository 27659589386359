import React from "react";
import PropTypes from "prop-types";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
import { Grid, Typography } from "@material-ui/core";

function OrientationWarning(props) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      style={{ height: "100%" }}
    >
      <Grid item>
        <Typography align="center">
          {" "}
          <ScreenRotationIcon
            style={{ fontSize: "8rem", color: "cornflowerblue" }}
          />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h1" align="center">
          Please switch to portrait mode for better experience
        </Typography>
      </Grid>
    </Grid>
  );
}

OrientationWarning.propTypes = {};

export default OrientationWarning;
