import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Grid, Button } from "@material-ui/core";
import PrimaryButton from "@components/primary-button";
import { useParams } from "react-router-dom";
import updateFeedback from "../../graphql/feedback/hooks/updateFeedback";
import Modal from "@components/modal";
import FeedbackInput from "@components/feedback-input";
import { useDispatch } from "react-redux";
import { action } from "@root/store/ui/reducer";
import langFn from "@util/lang";

import { action as feedbackActions } from "@root/store/feedback/reducer";

function AdditionalFeedback(props) {
  const [feedback, setFeedback] = useState("");
  const [dialogFeedback, setDialogFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const dispatch = useDispatch();

  const { billNumber } = useParams();

  const onSave = (payload) => {
    // if (!Boolean(String(feedback).trim())) {
    //   return setFeedbackError(true);
    // } else {
    //   setFeedbackError(false);
    // }
    // updateFeedback({
    //   billNumber,
    //   feedback,
    // })
    //   .then((res) => {
    //     dispatch(action.updateFeedbackSubmitted(true));
    //     setFeedbackSubmitted(true);
    //   })
    //   .catch((e) => {});
    //dispatch(action.updateFeedbackSubmitted(true));
    dispatch(feedbackActions.updateAdditionalFeedbackSubmitted(true));
    setFeedbackSubmitted(true);
  };

  React.useEffect(() => {
    if (Boolean(feedback)) {
      setFeedbackError(false);
    }
  }, [feedback]);

  if (feedbackSubmitted) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={feedback}
            label={langFn("Additional Feedback (Optional)")}
            onChange={({ target: { value } }) => setFeedback(value)}
            error={feedbackError}
            helperText={
              feedbackError ? langFn("Please fill additional feedback") : null
            }
            fullWidth
            multiline
            maxRows={10}
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton onClick={onSave}>
            {" "}
            {langFn("Submit Feedback")}
          </PrimaryButton>
        </Grid>
      </Grid>
      {dialogFeedback && (
        <Modal
          message={`${langFn("Thank You for Your Feedback")}!`}
          onClose={() => setDialogFeedback(false)}
        />
      )}
    </>
  );
}

AdditionalFeedback.propTypes = {};

export default AdditionalFeedback;
