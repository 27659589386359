import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SplitBill from "./SplitBill";
import orderAction from "../../store/order/api";
import * as orderSelector from "../../store/order/selector";
import * as itemSelector from "../../store/items/selector";
import * as uiSelector from "../../store/ui/selector";
import { action as UIAction } from "@store/ui/reducer";

const mapStateToProps = (state) => ({
  items: itemSelector.getGroupedItem(state),
  totalAmount: orderSelector.getItemTotalAmount(state),
  itemTotalAmount: orderSelector.getItemTotalAmount(state),
  checkoutFormStatus: uiSelector.checkoutFormStatus(state),
  splitBillStatus: uiSelector.splitBillStatus(state),
});

const mapDispatchToProps = {
  getOrderDetails: orderAction.getOrderDetails,
  updateTotalAmount: orderAction.updateTotalAmount,
  toggleCheckoutForm: UIAction.toggleCheckoutForm,
  toggleSplitBill: UIAction.toggleSplitBill,
  updateIamPaying: orderAction.updateIamPaying,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SplitBill));
