import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import { Typography, Grid, withStyles } from "@material-ui/core";
import langFn from "@util/lang";

function PaymentLoadingSpinner({ classes }) {
  const { isLoading } = useSelector((state) => state.payment);
  if (!isLoading) {
    return null;
  }

  return (
    <Dialog
      onClose={() => {}}
      aria-labelledby="simple-dialog-title"
      open
      className={classes.dialogWrapper}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs="12" container justifyContent="center">
          <CircularProgress color="inherit" />
        </Grid>
        <Grid item>
          <Typography align="center" variant="h2">
            {langFn("Confirming Payment")}...
          </Typography>
          <Typography align="center" variant="h2">
            {langFn("Hang tight")}!
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const Styles = {
  dialogWrapper: {
    zIndex: "1600 !important",
    "& .MuiCircularProgress-root": {
      height: "122px !important",
      width: "122px !important",
      color: "#fff",
    },
    "& .MuiTypography-root": {
      color: "#fff",
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      overflow: "hidden",
      boxShadow: "none",
    },
  },
};

PaymentLoadingSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(PaymentLoadingSpinner);
